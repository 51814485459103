import { combineReducers } from 'redux'
import SidebarReducer from './feature/sidebarSlice'
import { auth } from './auth/reducer'
import { wordConversion } from './wordConversion/reducer'
import { wordParaphrase } from './rephrasingManagement/reducer'
import { dataSet } from './dataSet/reducer'
import { dataLog } from './dataLog/reducer'
import { dashBoard } from './dashboard/reducer'
import { AIQueryList } from './queryManagment/reducer'
import { documentList } from './documentManagment/reducer'
import { dataSetCategory } from './dataSetCategory/reducer'
import { frontendCategoryPage } from './frontendCategoryPage/reducer'
import { documentEditList } from './documentEdit/reducer'
const appReducer = combineReducers({
  sidebar: SidebarReducer,
  auth,
  AIQueryList,
  documentList,
  documentEditList,
  wordConversion,
  wordParaphrase,
  dataSet,
  dataLog,
  dashBoard,
  dataSetCategory,
  frontendCategoryPage,
})

export const middlewares = []

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action)
  else return appReducer(state, action)
}

export default rootReducer
