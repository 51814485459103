import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'

export default function TranscriptModal({ visible, onClose, transcriptData }) {
  return (
    <div>
      <CModal
        size="xl"
        scrollable
        visible={visible}
        onClose={onClose}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader className="glassEffect">
          <CModalTitle id="ScrollingLongContentExampleLabel2">Transcript</CModalTitle>
          {/* <button
            type="button"
            className="btn-close btn-close-white"
            style={{ float: 'right' }}
            aria-label="Close"
          ></button> */}
        </CModalHeader>

        <CModalBody className="glassEffect">
          {transcriptData?.data?.data?.map((item) => (
            <p key={item?.id}>{item?.text}</p>
          ))}
        </CModalBody>
      </CModal>
    </div>
  )
}
