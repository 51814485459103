import NoAuthHeader from 'src/components/NoAuthHeader'
import Input from './input'
import { useEffect, useState } from 'react'

import skeltonLoaderImage from '../../../assets/adminBoard/skelton-loader.png'

import Messages from './messages'
import uuid from 'react-uuid'

export default function Chat({
  Category,
  subText,
  categoryTitle,
  categoryId,
  setExpandChat,
  expandChat,
  firstMessage,
}) {
  const [messages, setMessages] = useState([])
  useEffect(() => {
    if (firstMessage && categoryId) {
      let id = uuid()
      setMessages([
        {
          msg: "I'm Whizz, your AI Chatbot, trained on your data. I'm in learning mode, continuously improving with your feedback.",
          me: false,
          _id: id,
          id: id,
          // category_id: categoryId,
        },
      ])
    } else {
      setMessages([])
    }
  }, [firstMessage, categoryId])

  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatloading] = useState(false)
  const [streamingStatus, setStreamingStatus] = useState(false)
  // eslint-disable-next-line
  const [, setEventSource] = useState(null)

  const [selectedValue, setSelectedValue] = useState('HELPDESK')
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [runningStream, setRunningStream] = useState(null)

  function addMessage(msg) {
    setMessages((prev) => {
      // Check if the message with the given ID already exists in the array
      const existingIndex = prev.findIndex((item) => item.id === msg.id)

      if (existingIndex !== -1) {
        // If the message exists, update it with the new message
        const updatedMessages = [...prev]
        updatedMessages[existingIndex].msg = updatedMessages[existingIndex].msg + msg?.msg
        return updatedMessages
      } else {
        // If the message doesn't exist, add it as a new message
        return [...prev, msg]
      }
    })
  }

  function toggleLoading(value) {
    setLoading(value)
  }

  function stoporRegenrateMessage() {
    if (streamingStatus) {
      if (!loading) {
        setStreamingStatus(false)
        setChatloading(false)
        runningStream.close()
        toggleLoading(false)
      }
    } else {
      onRegenerate()
      setIsUserScroll(false)
      setStreamingStatus(true)
      setChatloading(true)
    }
  }
  function onRegenerate() {
    if (messages.length !== 0 && !messages[messages.length - 1].me) {
      var input = messages[messages.length - 2].msg
      setMessages(messages.slice(0, messages.length - 1))
      toggleLoading(true)
      const encodeInput = encodeURIComponent(input)

      const eventSource = new EventSource(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/conversion?question=${encodeInput}&category_id=${categoryId}&session_id=${localStorage.getItem(
          'sessionId',
        )}`,
      )
      setRunningStream(eventSource)
      var updateid = uuid()
      eventSource.onmessage = (event) => {
        setStreamingStatus(true)

        // const response = JSON.parse(event)
        toggleLoading(false)
        addMessage({
          msg: event.data,
          me: false,
          _id: updateid,
          id: updateid,
          category_id: categoryId,
        })
      }
      eventSource.onerror = (error) => {
        setStreamingStatus(false)
        setChatloading(false)
        console.error('Error with EventSource:', error)
        eventSource.close()
      }
      setEventSource(eventSource)

      return () => {
        if (eventSource) {
          eventSource.close()
        }
      }
    }
  }

  useEffect(() => {
    if (runningStream) {
      runningStream.close()
      setStreamingStatus(false)
      setChatloading(false)
      toggleLoading(false)
    }
  }, [categoryId])
  return (
    <>
      <div className={`chat-section ${expandChat ? 'expand-chat' : ''}`}>
        <div className="chattitle">
          <h5 className="mb-0">{"Welcome to Whizz's World: Let's Chat!!"}</h5>
        </div>
        {categoryId && categoryTitle && (
          <p
            style={{
              marginBottom: '0',
              fontSize: '12px',
              fontWeight: '500',
              position: 'absolute',
              top: '46px',
              left: '16px',
              color: '#faebd7',
            }}
          >
            {categoryTitle}
          </p>
        )}
        <div
          className={`chating-area ${
            messages.length > 0 ? 'cstm-chat-height cstm-chat-height-web' : ''
          }`}
        >
          {messages.length !== 0 ? (
            <>
              <Messages
                show={loading}
                messages={messages}
                setMessages={setMessages}
                toggleLoading={toggleLoading}
                category={Category?.Category ? Category?.Category : selectedValue}
                categoryId={categoryId}
                addMessage={addMessage}
                streamingStatus={streamingStatus}
                setStreamingStatus={setStreamingStatus}
                runningStream={runningStream}
                setRunningStream={setRunningStream}
                isUserScroll={isUserScroll}
                setChatloading={setChatloading}
                setIsUserScroll={setIsUserScroll}
              />
            </>
          ) : (
            <img src={skeltonLoaderImage} alt="" className="skelton-img" />
          )}
        </div>
        <div className="p-3">
          <div className="chat-send">
            {messages.length > 0 && (
              <input
                type="button"
                onClick={stoporRegenrateMessage}
                className="btn btn-primary mb-3"
                style={{ borderRadius: '100px' }}
                value={streamingStatus || loading ? 'Stop Generating ' : 'Regenerate Response '}
              />
            )}
            <Input
              setStreamingStatus={setStreamingStatus}
              setRunningStream={setRunningStream}
              maxwidth
              chatLoading={chatLoading}
              setChatloading={setChatloading}
              toggleLoading={toggleLoading}
              addMessage={addMessage}
              category={Category?.Category ? Category.Category : selectedValue}
              categoryId={categoryId}
              subText={subText}
              setIsUserScroll={setIsUserScroll}
            />
          </div>
        </div>
      </div>
    </>
  )
}
