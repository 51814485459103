import React, { useState } from 'react'
import SendIcon from '../../assets/adminBoard/send.svg'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Input({
  cstmBoard,
  addMessage,
  toggleLoading,
  chatLoading,
  categoryId,
  setChatloading,
  subText,
  maxwidth,
  dashboardId,
  setStreamingStatus,
  setRunningStream,
  setIsUserScroll,
  inputFromMainScreen,
}) {
  const [input, setInput] = useState('')
  const [, setEventSource] = useState(null)
  const [chart_id, setChart_id] = useState('')
  const [chart_schema, setChart_schema] = useState([])
  const [, setChartGenerateResponse] = useState([])
  let sessionId = ''
  // function handleInput() {
  //   var id = uuid()

  //   addMessage({
  //     msg: input,
  //     me: true,
  //     _id: id,
  //     id: id,
  //     category_id: categoryId,
  //   })
  //   setInput('')
  //   toggleLoading(true)
  //   setIsUserScroll(false)
  //   setChatloading(true)
  //   const encodeInput = encodeURIComponent(input)
  //   const eventSource = new EventSource(
  //     `${process.env.REACT_APP_API_ENDPOINT}/conversion?question=${encodeInput}&category_id=${categoryId}&session_id=${sessionId}`,
  //   )
  //   setRunningStream(eventSource)

  //   var updateid = uuid()

  //   eventSource.onmessage = (event) => {
  //     setStreamingStatus(true)

  //     // const response = JSON.parse(event)
  //     toggleLoading(false)

  // addMessage({
  //   msg: event.data,
  //   me: false,
  //   _id: updateid,
  //   id: updateid,
  //   category_id: categoryId,
  // })
  //   }
  //   eventSource.onerror = (error) => {
  //     setStreamingStatus(false)
  //     setChatloading(false)
  //     console.error('Error with EventSource:', error)
  //     eventSource.close()
  //   }
  //   setEventSource(eventSource)

  //   return () => {
  //     if (eventSource) {
  //       eventSource.close()
  //     }
  //   }
  // }

  const handleGenerate = async () => {
    var id = uuid()
    addMessage({
      msg: input,
      me: true,
      _id: id,
      id: id,
    })
    setInput('')
    toggleLoading(true)
    setChatloading(true)
    let payload = {
      dashboard_id: dashboardId,
      type: 'bar-chart',
      prompt: input,
    }
    //  setIsPromptGenerating(true)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/`,
        payload,
      )
      console.log('My resp ', response)
      setChatloading(false)
      toggleLoading(false)
      let schema = response.data.schema.fields

      const updatedSchema = schema.map((obj) => ({ ...obj, chart_id: response.data.chart_id }))
      setChart_schema(updatedSchema)
      setChart_id(response.data.chart_id)
      setChartGenerateResponse(response.data.data)
      var updateId = uuid()
      addMessage({
        msg: updatedSchema,
        me: false,
        _id: updateId,
        id: updateId,
        chart_id: response.data.chart_id,
      })
      // plotChart()
    } catch {
      setChatloading(false)
      toggleLoading(false)
      // toast.error('something went wrong')
      var updateIds = uuid()
      addMessage({
        msg: "I'm sorry, but I couldn't find any data that matches your request at the moment. If you have a different question or need assistance with something else, please feel free to ask, and I'll do my best to assist you.",
        me: false,
        _id: updateIds,
        id: updateIds,
      })
    }
  }
  return (
    <div
      className={`botm ${cstmBoard && 'p-3 cstm-background'} `}
      style={{
        maxWidth: !maxwidth ? '650px' : '',
      }}
    >
      <div className={`${!cstmBoard && 'input-box'} `}>
        <input
          className={`input-area ${cstmBoard && 'cstm-send-input'} `}
          disabled={chatLoading}
          style={{
            color: cstmBoard && 'white',
          }}
          autoFocus={true}
          onKeyDown={(e) => e.keyCode === 13 && input !== '' && handleGenerate()}
          onChange={(e) => setInput(e.target.value)}
          value={input}
          type="text"
          placeholder="Search"
        />

        <button
          className="cstm-icon send-icon cstm-send-icon"
          style={{
            // rotate: '90deg',
            marginTop: '14px',
            marginRight: '8px',
            color: chatLoading || input.length === 0 ? '#ccc' : '#4BC641',
            cursor: chatLoading || input.length === 0 ? 'unset' : 'pointer',
          }}
          disabled={chatLoading || input.length === 0}
          onClick={() => handleGenerate()}
        >
          <img src={SendIcon} alt="send" style={{ cursor: 'pointer' }} />
        </button>
      </div>
      <p className="brand-name">{subText}</p>
    </div>
  )
}

Input.propTypes = {
  addMessage: PropTypes.func,
  toggleLoading: PropTypes.func,
  chatLoading: PropTypes.bool,
  category: PropTypes.string,
}
