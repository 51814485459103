export const questionArray = [
  'What are the benefits of smart home automation?',

  'What are the benefits of smart locks?',
  'Tell me about Samsung the Galaxy Tab S9?',
  'What is sanity testing?',
  'What is function testing?',
  'What are the types of testing?',
  'Tell me about Z flip?',
  'What is Z flip ram size?',
  'What about screen size of Z flip?',
]
export const chartQuestionArray = [
  'Get employee count by country',
  'Company wise sales',
  'Top distributors by company',
]
