import { cilCopy, cilCrop, cilX, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { generateVIBGYORColorCodes } from './data'
import { Box, Skeleton } from '@mui/material'
import closeIcon from '../../../assets/adminBoard/close-i.svg'
export default function ChartEmbed() {
  const codeCopied = () => toast.success('Code Copied')
  const [responseData, setResponseData] = useState([])
  const [chartData, setChartData] = useState(null)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const parts = location.pathname.split('/')
  const chartIds = parts[parts.length - 1]
  const [chartId, setChartId] = useState(chartIds)
  const navigate = useNavigate()
  const getChartList = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/details?chart_id=` + chartIds,
      )
      setChartId(response?.data?.id)
      setResponseData(response?.data)
      let datas = {
        data: {
          labels: response?.data?.data?.label,
          datasets: [
            {
              data: response?.data?.data?.data,
              backgroundColor: generateVIBGYORColorCodes(response?.data?.data?.data?.length),
              borderColor: '#fff',
              borderWidth: 0.5,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display:
                response?.data?.type === 'pie-chart' || response?.data?.type === 'dognut-chart'
                  ? true
                  : false,
            },
          },
        },
      }
      setChartData(datas)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error while processing the API response:', error)
    }
  }
  const copyCode = () => {
    const iframeUrl = `<iframe src="https://app.whizzapp.ai/mychart-embbed/${chartId}" title="Chart" width="600" height="600"/>`
    navigator.clipboard.writeText(iframeUrl)
    codeCopied()
  }
  useEffect(() => {
    getChartList()
    // eslint-disable-next-line
  }, [])
  return (
    <div
      className="d-flex justify-content-center align-items-center mr-4"
      style={{ width: '100vw' }}
    >
      {loading ? (
        <Box className="chart-container-cstm">
          <Skeleton variant="rectangular" width={1000} height={250} />

          <Box sx={{ pt: 0.5, width: 210 }}>
            <Skeleton width={1000} />
            <Skeleton width={330} />

            <Skeleton width="60%" />
          </Box>
        </Box>
      ) : (
        <div
          className="embed-chart"
          style={{
            width:
              responseData?.type === 'pie-chart' || responseData?.type === 'doghnut-chart'
                ? '48%'
                : '',
            marginTop: '0',
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h4>{responseData?.prompt}</h4>
            </div>
            <div className="d-flex justify-content-around">
              <CButton onClick={copyCode}>
                <CIcon style={{ cursor: 'pointer' }} icon={cilCopy}></CIcon>
                <ToastContainer />
              </CButton>
              <CButton onClick={() => navigate(-1)} style={{ marginLeft: '8px' }}>
                <CIcon style={{ cursor: 'pointer' }} icon={cilX}></CIcon>
                <ToastContainer />
              </CButton>
            </div>
            {/* <h4>Attach</h4> */}
          </div>

          {responseData?.type === 'pie-chart' && chartData !== null && (
            <Pie data={chartData?.data} options={chartData?.options} />
          )}
          {responseData?.type === 'dognut-chart' && chartData !== null && (
            <Doughnut data={chartData?.data} options={chartData?.options} />
          )}

          {responseData?.type === 'line-chart' && chartData !== null && (
            <Line data={chartData?.data} options={chartData?.options} />
          )}

          {responseData?.type === 'bar-chart' && chartData !== null && (
            <Bar data={chartData?.data} options={chartData?.options} />
          )}
        </div>
      )}
    </div>
  )
}
