import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import './dashboard.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTooltip,
} from '@coreui/react'
import { AddModal } from './addModal'
import { useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCheckCircle,
  cilCopy,
  cilPencil,
  cilResizeBoth,
  cilSpeedometer,
  cilTrash,
} from '@coreui/icons'
import axios from 'axios'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateVIBGYORColorCodes } from './data'
import { toast } from 'react-toastify'
import CreateNew from './createNew'
ChartJS.register(ArcElement, Tooltip, Legend)

const UserDashboard = ({ refresh }) => {
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [objectData, setObjectData] = useState(null)
  const [dashboardName, setDashboardName] = useState('')
  const skeltonArray = Array(6).fill(1)
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [allDashboardsOfDB, setAllDashboardsOfDB] = useState([])
  const [chartContainers, setChartContainers] = useState([])
  const [loadingDashboard, setLoadingDashboard] = useState(false)
  const [expandAccordian, setExpandAccordian] = useState(false)

  const codeCopied = () => toast.success('Code Copied')
  const navigate = useNavigate()
  const parts = location.pathname.split('/')
  const dashboardId = parts[parts.length - 1]
  // Example usage: Generate 5 bright color codes
  const getAllDashboardsByDBid = (dbId) => {
    setLoadingDashboard(true)
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/?database_id=` + dbId)
      .then((resp) => {
        const modifiedData = resp?.data?.map((nav) => ({
          ...nav,
          isEditable: false,
        }))
        if (resp?.status === 200) {
          setLoadingDashboard(false)
          setAllDashboardsOfDB(modifiedData)
        }
      })
      .catch((error) => {
        setLoadingDashboard(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getAllDashboardsByDBid('64d4816c2b8501541c441cd1')
  }, [])
  const getChartList = async () => {
    if (dashboardId === 'mychart') {
      return
    }
    setIsChartLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/?dashboard_id=` + dashboardId,
      )

      const modifiedJSON = response.data.map((item) => {
        return {
          ...item,
          data: {
            labels: item?.data?.label,
            datasets: [
              {
                data: item?.data?.data,
                backgroundColor: generateVIBGYORColorCodes(item?.data?.data?.length),
                borderColor: '#fff',
                borderWidth: 0.5,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: item?.type === 'pie-chart' || item?.type === 'dognut-chart' ? true : false,
              },
            },
          },
        }
      })
      setChartContainers(modifiedJSON)

      setIsChartLoading(false)
    } catch (error) {
      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
      setIsChartLoading(false)
    }
  }

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id.toString())
  }

  const handleDeleteChart = async (id) => {
    let response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/?chart_id=` + id,
    )

    if (response?.status === 204) {
      getChartList()
    }
  }

  const handleEdit = (id, index) => {
    console.log('In 1st')
    let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)
    setAllDashboardsOfDB((prevNavList) => {
      const updatedNavList = [...prevNavList]
      updatedNavList[indexOfEdit].isEditable = true
      console.log('In 1stAA')
      return updatedNavList
    })
  }
  const handleDeleteDashboard = async (id, dbId) => {
    let response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/` + id,
    )

    if (response?.status === 204) {
      getAllDashboardsByDBid(dbId)
    }
  }
  const handleEditDashboardName = (id, newtitle) => {
    console.log('In 2nd')
    try {
      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
          id: id,
          title: newtitle,
        })
        .then((resp) => {
          if (resp?.status === 200) {
            let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)
            // setRefresh(!refresh)
            setAllDashboardsOfDB((prevNavList) => {
              const updatedNavList = [...prevNavList]
              updatedNavList[indexOfEdit].title = newtitle
              updatedNavList[indexOfEdit].isEditable = false

              return updatedNavList
            })
            setDashboardName('')
          } else if (resp?.status === 422) {
            toast.error('Title already exist.')
          }
        })
        .catch((error) => {
          // Handle the error here
          if (error?.response?.status === 422) {
            toast.error('Title already exist.')
          } else {
            toast.error('Something went wrong')
          }
          console.log('An error occurred:', error)
          // You can add more specific error handling based on the error response if needed
        })
    } catch (error) {
      console.error('An error occurred:', error)
      // Handle the catch block error here if necessary
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleExpand = (container) => {
    navigate('/mychart-embbed/' + container?.id)
  }

  const handleDrop = (e, targetId) => {
    const sourceId = e.dataTransfer.getData('text/plain')

    // Convert sourceId and targetId to numbers if they are in string format

    const sourceIndex = chartContainers.findIndex((container) => container.id === sourceId)
    const targetIndex = chartContainers.findIndex((container) => container.id === targetId)

    if (sourceIndex === -1 || targetIndex === -1) {
      console.error('Invalid source or target ID.')
      return
    }

    // setChartContainers((prevState) => {
    //   const updatedArray = [...prevState]
    //   ;[updatedArray[sourceIndex], updatedArray[targetIndex]] = [
    //     updatedArray[targetIndex],
    //     updatedArray[sourceIndex],
    //   ]

    //   return updatedArray
    // })
  }

  const handleEditModal = (data) => {
    setObjectData(data)
    setVisible(true)
  }

  const copyCode = () => {
    const iframeUrl = `<iframe src="https://whizz-staging.cubettech.in/mychartembed-dashboard/${dashboardId}" width="600" height="600"></iframe>`
    navigator.clipboard.writeText(iframeUrl)
    codeCopied()
  }

  useEffect(() => {
    getChartList()
    // eslint-disable-next-line
  }, [location.pathname, refresh])

  return (
    <div className="ml-2" onDragOver={handleDragOver}>
      <>
        <div className="d-md-none d-block">
          <Accordion
            expanded={expandAccordian}
            style={{
              background: '#121d12',
              color: '#fff',
              marginBottom: '8px',
              marginRight: '16px',
            }}
            onChange={() => setExpandAccordian(!expandAccordian)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              color="white"
            >
              <Typography>E-commerce Database</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allDashboardsOfDB.map((nav, index) => (
                <div
                  onClick={() => {
                    navigate('/mychart/' + nav?.id)
                  }}
                  className={dashboardId === nav?.id ? 'activedatabase' : ''}
                  key={nav?.id}
                  style={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    color: dashboardId === nav?.id ? 'white' : 'white',
                    display: 'flex',
                    padding: '8px 0',
                    alignItems: 'center',
                    paddingLeft: '16px',
                  }}
                >
                  <CIcon icon={cilSpeedometer} className="mr-2" />
                  <>
                    {!nav?.isEditable ? (
                      <span
                        style={{
                          width: '250px',
                          marginLeft: '8px',
                          marginRight: '8px',
                          maxWidth: '250px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: '14px',
                        }}
                      >
                        {nav?.title}
                      </span>
                    ) : (
                      <TextField
                        id="standard-basic"
                        style={{ width: '250px', marginLeft: '8px', marginRight: '8px' }}
                        inputProps={{ style: { color: 'white' } }}
                        onChange={(e) => setDashboardName(e.target.value)}
                        defaultValue={nav?.title}
                        variant="standard"
                      />
                    )}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        nav?.isEditable
                          ? handleEditDashboardName(
                              nav?.id,
                              dashboardName === '' ? nav?.title : dashboardName,
                            )
                          : handleEdit(nav?.id, index)
                      }
                    >
                      <CIcon
                        icon={nav?.isEditable ? cilCheckCircle : cilPencil}
                        style={{ marginRight: '8px' }}
                      />
                    </span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteDashboard(nav?.id, nav?.database_id)}
                    >
                      <CIcon icon={cilTrash} />
                    </span>
                  </>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
        {dashboardId === 'mychart' && <CreateNew />}
        <div className="d-flex justify-content-between cstm-chart-title-mb">
          <div className="selection__title">
            {chartContainers?.length > 0 && chartContainers[0].dashboard?.title}
          </div>
          {dashboardId !== 'mychart' && (
            <div className="cstm-chart-button-section">
              <CButton style={{ marginRight: '16px' }} className="cstm-btn" onClick={copyCode}>
                <CIcon style={{ cursor: 'pointer' }} icon={cilCopy}></CIcon>
              </CButton>

              <CButton className="cstm-btn" onClick={() => setVisible(true)}>
                + Add Card
              </CButton>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap  ">
          {chartContainers?.length > 0 &&
            chartContainers?.map(
              (container) =>
                container.data && (
                  <div
                    key={container.id}
                    className="chart-container"
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, container.id)}
                    onDragOver={() => handleDragOver}
                    onDrop={(e) => handleDrop(e, container.id)}
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="title-container">{container.prompt}</h6>

                      <div>
                        <CIcon
                          icon={cilPencil}
                          onClick={() => handleEditModal(container)}
                          className=" cstm-icn"
                        />
                        <CIcon
                          icon={cilResizeBoth}
                          onClick={() => handleExpand(container)}
                          className="mx-2 cstm-icn"
                        />
                        <CIcon
                          icon={cilTrash}
                          onClick={() => handleDeleteChart(container.id)}
                          className="cstm-icn"
                        />
                      </div>
                    </div>
                    {container.type === 'pie-chart' && container.data && (
                      <div>
                        <Pie data={container.data} options={container?.options} />
                      </div>
                    )}
                    {container.type === 'dognut-chart' && (
                      <div>
                        <Doughnut data={container.data} options={container?.options} />
                      </div>
                    )}

                    {container.type === 'line-chart' && (
                      <div>
                        <Line data={container.data} options={container?.options} />
                      </div>
                    )}

                    {container.type === 'bar-chart' && (
                      <div>
                        <Bar data={container.data} options={container?.options} />
                      </div>
                    )}
                  </div>
                ),
            )}
          {isChartLoading &&
            dashboardId !== 'mychart' &&
            skeltonArray?.map((d, index) => (
              <Box key={index} className="chart-container">
                <Skeleton variant="rectangular" width={330} height={250} />

                <Box sx={{ pt: 0.5, width: 210 }}>
                  <Skeleton width={330} />
                  <Skeleton width={330} />

                  <Skeleton width="60%" />
                </Box>
              </Box>
            ))}
        </div>
      </>
      <AddModal
        visible={visible}
        setVisible={setVisible}
        data={objectData}
        getChartList={getChartList}
      />
      {/* <ViewModal expand={expand} setExpand={setExpand} selectedContainer={selectedContainer} /> */}
      <CModal show={true}>
        <CModalHeader closeButton>
          <CModalTitle>Confirm Deletion</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to delete this item?</CModalBody>
        <CModalFooter>
          <CButton color="secondary">Cancel</CButton>
          <CButton color="danger">Delete</CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default UserDashboard
