import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CModalTitle,
  CSpinner,
} from '@coreui/react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js'
import { useEffect, useState } from 'react'
import chart1 from '../../../assets/adminBoard/graph-1.svg'
import chart2 from '../../../assets/adminBoard/graph-2.svg'
// import chart3 from ../../../assets/adminBoard/graph-1.svg'
import sendImg from '../../../assets/adminBoard/send.svg'
import chartFailed from '../../../assets/images/avatars/chartfailed.svg'
import chart4 from '../../../assets/adminBoard/graph-3.svg'
import axios from 'axios'
import { generateVIBGYORColorCodes } from './data'
import { toast } from 'react-toastify'
import { chartQuestionArray } from 'src/utility/chatGptQuestions'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement)
export const AddModal = ({ visible, setVisible, data, getChartList }) => {
  const [selectedValue, setSelectedValue] = useState('')
  const [promptMsg, setPromptMsg] = useState('')
  const [chartData, setChartData] = useState(null)
  const [chart_id, setChart_id] = useState('')
  const [chart_schema, setChart_schema] = useState([])
  const [isChartGenerating, setIsChartGenerating] = useState(false)
  const [, setChartGenerateResponse] = useState([])

  const [, setIsPromptGenerating] = useState(false)
  const parts = window.location.pathname.split('/')
  const dashboardId = parts[parts.length - 1]
  const [labeltoPlot, setLabeltoPlot] = useState(null)
  const [datatoPlot, setDatatoPlot] = useState(null)
  const [isPlotError, setIsPlotError] = useState(false)
  const [screen, setScreen] = useState('1')
  const [datatoPlotType, setDatatoPlotType] = useState(null)
  function convertToTitleCase(input) {
    const words = input.split('_')
    const titleCase = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    return titleCase
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value)
  }
  useEffect(() => {
    if (data) {
      setSelectedValue(data?.chartType?.toString())
    }
  }, [data])

  const handleGenerate = async () => {
    let payload = {
      dashboard_id: dashboardId,
      type: selectedValue,
      prompt: promptMsg,
    }
    setIsPromptGenerating(true)
    setIsChartGenerating(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/`,
        payload,
      )
      let schema = response.data.schema.fields
      setChart_schema(schema)
      setChart_id(response.data.chart_id)
      setChartGenerateResponse(response.data.data)
      setIsChartGenerating(false)
      setIsPromptGenerating(false)
      setScreen('3')
    } catch (error) {
      setIsPromptGenerating(false)
      setIsChartGenerating(false)

      toast.error('something went wrong')
      setChart_schema([])
      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
    } finally {
      setIsChartGenerating(false)

      setIsPromptGenerating(false) // Make sure to set it back to false whether there was an error or not.
    }
  }

  const plotChart = () => {
    console.log(datatoPlotType)
    if (datatoPlotType !== 'integer' && datatoPlotType !== 'number') {
      setIsPlotError(true)
      return
    }
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/generate`, {
        chart_id: chart_id,
        keys: [
          {
            key: 'label',
            field: labeltoPlot,
          },
          {
            key: 'data',
            field: datatoPlot,
          },
        ],
      })
      .then((resp) => {
        setScreen('4')
        let datas = {
          data: {
            labels: resp?.data?.data?.label,
            datasets: [
              {
                data:
                  datatoPlotType === 'number'
                    ? resp?.data?.data?.data?.map((str) => +str)
                    : resp?.data?.data?.data,
                backgroundColor: generateVIBGYORColorCodes(resp?.data?.data?.data?.length),
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display:
                  selectedValue === 'pie-chart' || selectedValue === 'dognut-chart' ? true : false,
              },
            },
          },
        }
        setChartData(datas)
      })

    // const labels = chartGenerateResponse.map((product) => product[labeltoPlot])
    // const unitPrices = chartGenerateResponse.map((product) => product[datatoPlot])
  }
  const handleAddToHome = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/add_to_dashboard`,
        {
          chart_id: chart_id,
        },
      )
      setVisible(false)
      setChart_schema([])
      setChart_id([])
      getChartList()
      setPromptMsg('')
      setSelectedValue('')
    } catch (error) {
      setVisible(false)

      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
    }
  }

  useEffect(() => {
    setIsPlotError(false)
  }, [screen])

  return (
    <CModal
      size="lg"
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => {
        setVisible(false)
        setScreen('1')
        setChart_id([])
        getChartList()
        setPromptMsg('')
        setSelectedValue('')
      }}
    >
      <CModalHeader className="customBoardModalbg cstmModalRadius">
        <CModalTitle>
          {screen === '1' && 'Select Chart'}
          {screen === '2' && 'Generate Chart'}
          {screen === '3' && 'Add Chart'}
        </CModalTitle>
      </CModalHeader>
      <CModalBody style={{ background: '#121d12' }}>
        {screen === '1' && (
          <div className="col-md-12 cstm-flex-mb">
            <label className="col-md-4 " onClick={() => setSelectedValue('pie-chart')}>
              <span
                className="plan-details"
                style={{
                  backgroundColor: '#000',
                  marginRight: '8px',
                  border: selectedValue === 'pie-chart' ? '3px solid #4bc641' : '1px solid #000',
                  borderRadius: '20px',
                }}
              >
                <img
                  src={chart1}
                  alt="1"
                  width={'219px'}
                  height={'177px'}
                  className="cstm-chartimage"
                />
              </span>
            </label>
            <label className="col-md-4 " onClick={() => setSelectedValue('dognut-chart')}>
              <span
                className="plan-details"
                style={{
                  backgroundColor: '#000',
                  marginRight: '8px',
                  border: selectedValue === 'dognut-chart' ? '3px solid #4bc641' : '1px solid #000',
                  borderRadius: '20px',
                }}
              >
                <img
                  src={chart2}
                  alt="1"
                  width={'219px'}
                  height={'177px'}
                  className="cstm-chartimage"
                />
              </span>
            </label>
            <label className="col-md-4 " onClick={() => setSelectedValue('bar-chart')}>
              <span
                className="plan-details"
                style={{
                  backgroundColor: '#000',
                  marginRight: '8px',
                  border: selectedValue === 'bar-chart' ? '3px solid #4bc641' : '1px solid #000',
                  borderRadius: '20px',
                }}
              >
                <img
                  src={chart4}
                  alt="1"
                  width={'219px'}
                  height={'177px'}
                  className="cstm-chartimage"
                />
              </span>
            </label>
          </div>
        )}
        {screen === '2' && (
          <div className="grid" style={{ width: '100%' }}>
            <div
              className="row"
              style={{
                display: 'flex',
                marginLeft: '0',
                marginRight: '0',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ width: '100%' }}>
                <textarea
                  placeholder="Enter the prompt"
                  style={{ width: '100%' }}
                  value={data?.prompt || promptMsg}
                  className="cstm-textareachart cstm-textbox"
                  onChange={(e) => setPromptMsg(e.target.value)}
                />
                <p style={{ color: 'gray', fontSize: '12px' }}>
                  Example: Please create a bar chart comparing the sales performance of different
                  products for the first half of the year. Make sure to label the axes and provide a
                  title for the chart.
                </p>
              </div>

              <div className="row g-2">
                {chartQuestionArray.map((item, i) => (
                  <div className="col-md-6" key={i}>
                    <div
                      onClick={() => setPromptMsg(item)}
                      style={{ border: promptMsg === item && '1px solid #fff' }}
                      className="question-box"
                    >
                      <p className="mb-0">{item}</p>
                      <span className="send-ques">
                        <img src={sendImg} alt="snd" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {screen === '3' && (
          <>
            <h6>Select Labels</h6>
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
              {chart_schema.map((item, i) => (
                <div
                  key={i}
                  className="d-flex p-2  cstm-chip-board mx-2 my-1"
                  style={{
                    border: item.name === labeltoPlot ? '2px solid #fff' : '',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setLabeltoPlot(item.name)
                    setIsPlotError(false)
                  }}
                >
                  <span>{convertToTitleCase(item.name)}</span>
                </div>
              ))}
            </div>
            <hr />

            <h6>Select Data to Plot</h6>
            <div className="d-flex " style={{ flexWrap: 'wrap' }}>
              {chart_schema
                ?.filter((itm) => itm?.type !== 'string')
                .map((item, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setDatatoPlot(item.name)
                      setDatatoPlotType(item.type)
                      setIsPlotError(false)
                    }}
                    className="d-flex p-2 cstm-chip-board  mx-2 my-1"
                    style={{
                      border: item.name === datatoPlot ? '2px solid #fff' : '',
                      cursor: 'pointer',
                    }}
                  >
                    <span>{convertToTitleCase(item.name)}</span>
                  </div>
                ))}
            </div>
            {chart_schema?.filter((itm) => itm?.type !== 'string').length === 0 && (
              <>
                <p className="text-center">No data to plot</p>
              </>
            )}
            {isPlotError && (
              <p className="cstm-err">Oops !! Can&apos;t create chart with these data</p>
            )}
          </>
        )}

        {screen === '4' && (
          <div>
            {selectedValue === 'pie-chart' && chartData !== null && (
              <Pie data={chartData?.data} options={chartData?.options} />
            )}

            {selectedValue === 'dognut-chart' && chartData !== null && (
              <Doughnut data={chartData?.data} />
            )}
            {selectedValue === 'line-chart' && chartData !== null && (
              <Line data={chartData?.data} options={chartData?.options} />
            )}
            {selectedValue === 'bar-chart' && chartData !== null && (
              <Bar data={chartData?.data} options={chartData?.options} />
            )}
          </div>
        )}
      </CModalBody>
      <CModalFooter className="customBoardModalbg cstmModalRadiusBottom">
        {screen === '1' && (
          <CButton
            onClick={() => {
              setVisible(!visible)
              setScreen('1')
            }}
            color="secondary"
          >
            Cancel
          </CButton>
        )}
        {screen === '2' && (
          <CButton onClick={() => setScreen('1')} color="secondary">
            Back
          </CButton>
        )}
        {screen === '3' && (
          <CButton onClick={() => setScreen('2')} color="secondary">
            Back
          </CButton>
        )}
        {screen === '4' && (
          <CButton onClick={() => setScreen('3')} color="secondary">
            Back
          </CButton>
        )}
        {screen === '1' && (
          <CButton
            disabled={selectedValue === ''}
            style={{ color: selectedValue === '' && '#fff' }}
            onClick={() => setScreen('2')}
            color="primary"
          >
            Next
          </CButton>
        )}
        {screen === '2' && (
          <CButton
            onClick={() => {
              handleGenerate()
            }}
            color="primary"
            disabled={isChartGenerating || promptMsg?.length === 0}
            style={{ color: (isChartGenerating || promptMsg?.length === 0) && '#fff' }}
          >
            Generate {isChartGenerating && <CSpinner size="sm" style={{ marginLeft: '8px' }} />}
          </CButton>
        )}
        {screen === '3' && (
          <CButton onClick={() => plotChart()} color="primary">
            Next
          </CButton>
        )}
        {screen === '4' && (
          <CButton onClick={handleAddToHome} color="primary">
            Add To Home screen
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  )
}
