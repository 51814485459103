import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react'
import React, { useRef, useState } from 'react'
import { siteMap } from 'src/redux/services/siteMap'
import addAudioImg from '../../assets/adminBoard/audioImg.svg'
import xmlImg from '../../assets/adminBoard/xml.svg'
export default function AddUrl({
  sourceType,

  setUrlFile,
  setSiteMapUrl,
  setSourceType,
  setFileError,
  fileError,
  setIsAddButtonDisable,
}) {
  const audioRef = useRef()

  return (
    <div>
      <CRow className="mb-3">
        <CCol md={12} className="ps-lg-2">
          <CFormLabel>Source Type</CFormLabel>
          <div className="d-flex mb-3">
            <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                style={{ border: '1px solid white' }}
                type="radio"
                name="flexRadioDefault"
                onChange={() => {
                  setSourceType('sitemap')
                  setUrlFile([])
                  setSiteMapUrl('')
                  setFileError('')
                }}
                value="sitemap"
                id="flexRadioDefault3"
                disabled
              />
              <label className="form-check-label">Sitemap</label>
            </div>
            <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                style={{ border: '1px solid white' }}
                onChange={() => {
                  setSourceType('audio')
                  setUrlFile([])
                  setSiteMapUrl('')
                  setFileError('')
                }}
                value="audio"
                id="flexRadioDefault1"
                defaultChecked
              />
              <label className="form-check-label">Audio</label>
            </div>
            <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                style={{ border: '1px solid white' }}
                onChange={() => {
                  setSourceType('video')
                  setUrlFile([])
                  setSiteMapUrl('')
                  setFileError('')
                }}
                value="video"
                id="flexRadioDefault2"
              />
              <label className="form-check-label">Video</label>
            </div>
          </div>
        </CCol>

        <CCol md={12} className="ps-lg-2">
          <div>
            <CFormLabel className="mt-2">
              {sourceType === 'sitemap'
                ? 'Sitemap URL'
                : sourceType === 'video'
                ? 'Video URL'
                : 'Audio File'}
            </CFormLabel>

            {sourceType === 'audio' ? (
              <>
                <div
                  className="d-grid justify-content-center mb-4 pt-4 pb-2"
                  style={{
                    cursor: 'pointer',
                    border: 'dashed 1px white',
                    borderRadius: '18px',
                  }}
                  onClick={() => {
                    audioRef.current.click()
                  }}
                >
                  <div className="d-grid justify-content-center">
                    <img src={addAudioImg} />
                  </div>
                  <p className="mt-3">Choose an Audio File.</p>
                </div>

                <CFormInput
                  ref={audioRef}
                  style={{ display: 'none' }}
                  required
                  className="mb-2"
                  name="audio"
                  type="file"
                  accept=".mp3"
                  onChange={(event) => {
                    event.preventDefault()
                    let prevFileObj
                    let errMsg = ''

                    let fileObj
                    let totalFileSize = 0
                    let filePortions = []
                    const acceptTypes = ['mp3']
                    // let files = urlFile ? urlFile : []

                    fileObj = event?.target?.files?.[0]

                    filePortions = (typeof fileObj?.name === 'string' ? fileObj.name : '').split(
                      '.',
                    )
                    filePortions = filePortions.pop()
                    filePortions = (
                      typeof filePortions === 'string' ? filePortions : ''
                    ).toLowerCase()
                    if (!acceptTypes.includes(filePortions)) {
                      errMsg = 'Please upload an mp3 file'
                      setIsAddButtonDisable(true)
                      setUrlFile([])
                    }

                    // if (files.length + 1 > 5) {
                    //   errMsg = 'Only 5 files accepted'
                    //   files = []
                    // }

                    // for (prevFileObj of files) {
                    //   if (!Number.isNaN(parseFloat(prevFileObj?.size))) {
                    //     totalFileSize += parseFloat(prevFileObj?.size)
                    //   }
                    // }

                    // if (!Number.isNaN(parseFloat(fileObj?.size))) {
                    //   totalFileSize += parseFloat(fileObj?.size)
                    // }

                    if (errMsg.trim() === '' && totalFileSize > 5000000) {
                      errMsg = 'Please select a file smaller than 5 MB'
                    }

                    if (typeof errMsg === 'string' && errMsg.trim() !== '') {
                      setFileError(errMsg)
                    } else {
                      setFileError('')
                      let obj = []
                      obj.push(event?.target?.files?.[0])
                      setUrlFile(obj)
                      setIsAddButtonDisable(false)
                    }
                  }}

                  // onChange={(event) => {
                  //   setAudioFile(event?.target?.files?.[0])
                  // }}
                />
              </>
            ) : (
              <div
                className="mb-4 pt-4 pb-2"
                style={{
                  cursor: 'pointer',
                  border: 'dashed 1px white',
                  borderRadius: '18px',
                }}
              >
                <div className="d-grid justify-content-center mb-4 pt-2 pb-2">
                  <img src={xmlImg} />
                </div>
                <div className="px-2">
                  <CFormInput
                    required
                    className="cstm-textbox"
                    placeholder="Enter URL"
                    name="url"
                    type="text"
                    onChange={(event) => {
                      setSiteMapUrl(event.target.value)
                      setFileError(null)
                    }}
                  />
                </div>
              </div>
            )}
            {/* <span style={{ color: 'red' }}>{sitemapUrlError}</span> */}
            <span style={{ color: 'red' }}>{fileError}</span>
          </div>
        </CCol>
      </CRow>
    </div>
  )
}
