import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import './dashboard.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import { AddModal } from './addModal'
import { useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilResizeBoth, cilTrash } from '@coreui/icons'
import axios from 'axios'
import { Box, Skeleton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateVIBGYORColorCodes } from './data'

ChartJS.register(ArcElement, Tooltip, Legend)

const EmbedDashboard = () => {
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [objectData, setObjectData] = useState(null)
  const skeltonArray = Array(6).fill(1)
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [chartContainers, setChartContainers] = useState([])

  const navigate = useNavigate()
  const parts = location.pathname.split('/')
  const dashboardId = parts[parts.length - 1]

  // Example usage: Generate 5 bright color codes

  const getChartList = async () => {
    setIsChartLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/?dashboard_id=` + dashboardId,
      )
      console.log('111111111', response.data)
      let responseData = response.data
      console.log(responseData)

      const modifiedJSON = response.data.map((item) => {
        return {
          ...item,
          data: {
            labels: item?.data?.label,
            datasets: [
              {
                data: item?.data?.data,
                backgroundColor: generateVIBGYORColorCodes(item?.data?.data?.length),
                borderColor: '#fff',
                borderWidth: 2,
              },
            ],
          },
        }
      })

      setChartContainers(modifiedJSON)

      setIsChartLoading(false)
    } catch (error) {
      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
      setIsChartLoading(false)
    }
  }

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id.toString())
  }

  const handleDeleteChart = async (id) => {
    let response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/?chart_id=` + id,
    )

    if (response?.status === 204) {
      getChartList()
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleExpand = (container) => {
    navigate('/embedChart/' + container?.id)
  }

  const handleDrop = (e, targetId) => {
    const sourceId = e.dataTransfer.getData('text/plain')

    // Convert sourceId and targetId to numbers if they are in string format

    const sourceIndex = chartContainers.findIndex((container) => container.id === sourceId)
    const targetIndex = chartContainers.findIndex((container) => container.id === targetId)

    if (sourceIndex === -1 || targetIndex === -1) {
      console.error('Invalid source or target ID.')
      return
    }

    // setChartContainers((prevState) => {
    //   const updatedArray = [...prevState]
    //   ;[updatedArray[sourceIndex], updatedArray[targetIndex]] = [
    //     updatedArray[targetIndex],
    //     updatedArray[sourceIndex],
    //   ]

    //   return updatedArray
    // })
  }

  const handleEditModal = (data) => {
    setObjectData(data)
    setVisible(true)
  }

  useEffect(() => {
    getChartList()
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <div className="p-4" onDragOver={handleDragOver}>
      <>
        <div className="d-flex justify-content-between">
          <div className="selection__title">
            {chartContainers?.length > 0 && chartContainers[0].dashboard?.title}
          </div>
        </div>

        <div className="d-flex flex-wrap  ">
          {chartContainers?.length > 0 &&
            chartContainers?.map(
              (container) =>
                container.data && (
                  <div
                    key={container.id}
                    className="chart-container"
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, container.id)}
                    onDragOver={() => handleDragOver}
                    onDrop={(e) => handleDrop(e, container.id)}
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="title-container">{container.prompt}</h6>

                      <div>
                        {/* <CIcon
                          icon={cilPencil}
                          onClick={() => handleEditModal(container)}
                          className=" cstm-icn"
                        /> */}
                        {/* <CIcon
                          icon={cilResizeBoth}
                          onClick={() => handleExpand(container)}
                          className="mx-2 cstm-icn"
                        /> */}
                        {/* <CIcon
                          icon={cilTrash}
                          onClick={() => handleDeleteChart(container.id)}
                          className="cstm-icn"
                        /> */}
                      </div>
                    </div>
                    {container.type === 'pie-chart' && container.data && (
                      <Pie data={container.data} />
                    )}
                    {container.type === 'doghnut-chart' && <Doughnut data={container.data} />}

                    {container.type === 'line-chart' && <Line data={container.data} />}

                    {container.type === 'bar-chart' && <Bar data={container.data} />}
                  </div>
                ),
            )}
          {isChartLoading &&
            skeltonArray?.map((d, index) => (
              <Box key={index} className="chart-container">
                <Skeleton variant="rectangular" width={330} height={250} />

                <Box sx={{ pt: 0.5, width: 210 }}>
                  <Skeleton width={330} />
                  <Skeleton width={330} />

                  <Skeleton width="60%" />
                </Box>
              </Box>
            ))}
        </div>
      </>

      {/* <ViewModal expand={expand} setExpand={setExpand} selectedContainer={selectedContainer} /> */}
      <CModal show={true}>
        <CModalHeader closeButton>
          <CModalTitle>Confirm Deletion</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to delete this item?</CModalBody>
        <CModalFooter>
          <CButton color="secondary">Cancel</CButton>
          <CButton color="danger">Delete</CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default EmbedDashboard
