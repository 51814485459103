import React from 'react'
import chartImg from '../../../assets/images/avatars/noDashboard.svg'
import { CButton } from '@coreui/react'
const CreateNew = ({ setHideStart, setVisible }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="card-dashboard" style={{ background: 'transparent' }}>
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          <img
            src={chartImg}
            alt="chart-img"
            style={{ maxWidth: '100%', height: 'calc(100vh - 336px)' }}
          />
        </div>
        <div className="d-flex justify-content-center ">
          <h1 className="cstm-font">Choose a dashboard and create your customized charts</h1>
        </div>
      </div>
    </div>
  )
}

export default CreateNew
