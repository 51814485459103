import React, { useEffect, useRef, useState } from 'react'
import ai3 from '../../assets/adminBoard/aiLogo.svg'
import user from '../../assets/images/avatars/user.png'
import PropTypes from 'prop-types'
import './chatbot.css'
import axios from 'axios'
import { Pie } from 'react-chartjs-2'
import { generateVIBGYORColorCodes } from '../UserDashboard/data'
import { Box } from '@mui/material'
import { CModal, CModalBody, CModalHeader } from '@coreui/react'
import uuid from 'react-uuid'

export default function Message({ me, msg, id, addMessage, cstmBoard }) {
  const [labelToPlot, setLabelToPlot] = useState(null)
  const [dataToPlot, setDataToPlot] = useState(null)
  const [dataToPlotType, setDataToPlotType] = useState(null)
  const [chart_id, setChart_id] = useState(null)

  const [chartData, setChartData] = useState([])
  const [isEnlargeModal, setIsEnlargeModal] = useState(false)

  const [isChartReady, setIsChartReady] = useState(false)
  const plotChart = () => {
    if (dataToPlotType !== 'integer') {
      var cuId = uuid()

      addMessage({
        msg: "Oops !! Can't create chart with these data",
        me: false,
        _id: cuId,
        id: cuId,
      })
      return
    }
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/generate`, {
        chart_id: chart_id,
        keys: [
          {
            key: 'label',
            field: labelToPlot,
          },
          {
            key: 'data',
            field: dataToPlot,
          },
        ],
      })
      .then((resp) => {
        let datas = {
          labels: resp?.data?.data?.label,
          datasets: [
            {
              data: resp?.data?.data?.data,
              backgroundColor: generateVIBGYORColorCodes(resp?.data?.data?.data?.length),
              borderColor: '#fff',
              borderWidth: 0.5,
            },
          ],

          // options: {
          //   plugins: {
          //     legend: {
          //       display:
          //         selectedValue === 'pie-chart' || selectedValue === 'dognut-chart' ? true : false,
          //     },
          //   },
          // },
        }

        console.log('Dtataaass ', datas)
        var cId = uuid()
        addMessage({
          msg: datas,
          me: false,
          _id: cId,
          id: cId,
        })
        setChartData(datas)
        setIsChartReady(true)
      })

    // const labels = chartGenerateResponse.map((product) => product[labeltoPlot])
    // const unitPrices = chartGenerateResponse.map((product) => product[datatoPlot])
  }
  useEffect(() => {
    var id = uuid()
    if (dataToPlot && labelToPlot) {
      // addMessage({
      //   msg: `I choosed ${labelToPlot} as label and ${dataToPlot} as data `,
      //   me: true,
      //   _id: id,
      //   id: id,
      // })
      plotChart()
    }
  }, [dataToPlot, labelToPlot])
  const handleSchema = (type, schema, chart_id, datatype) => {
    setChart_id(chart_id)
    if (type === 'data') {
      setDataToPlot(schema)
      setDataToPlotType(datatype)
    } else if (type === 'label') {
      setLabelToPlot(schema)
    }
  }

  const handleEnlarge = () => {
    setIsEnlargeModal(true)
  }

  return (
    <div
      className="chat-panel"
      style={{
        backgroundColor: !me ? 'transparent' : cstmBoard ? '#133a0ea6' : '#133a0ea6',
        color: !me ? '#fff' : cstmBoard ? '#fff ' : '#fff',
        flexDirection: me ? 'row-reverse' : 'initial',
        borderRadius: me ? '20px 0px 20px 20px' : '0px 20px 20px 20px',
      }}
    >
      <div className="chatIconDiv" style={{ marginLeft: me && '0px', marginRight: me && '32px' }}>
        <div className="chatIcon">
          {me && <img src={user} width="30" alt="" />}
          {!me && <img src={ai3} width="30" alt="" />}
        </div>
      </div>
      {typeof msg === 'string' && (
        <p
          id={'msg_text_pre_' + id}
          className="cstm-msg"
          style={{
            marginLeft: me ? '35px' : '28px',
            textAlign: me ? 'end' : 'inherit',
            overflow: 'hidden',
            fontFamily: 'Instrument Sans,sans-serif',
            wordBreak: 'break-word',
          }}
        >
          {msg?.trim()}
        </p>
      )}
      {typeof msg === 'object' && !msg.datasets && (
        <div style={{ marginLeft: '28px' }}>
          <p style={{ fontFamily: 'Instrument Sans,sans-serif' }}>
            {
              "Excellent! We've got some suggestions lined up for you. Just click on any of the elements below to proceed. If you have something else in mind, feel free to type your own question or request"
            }
          </p>
          <p style={{ fontFamily: 'Instrument Sans,sans-serif' }}>
            Please select a label field from the below list :{' '}
          </p>
          <div
            style={{ marginLeft: '28px', marginBottom: '20px', display: 'flex', flexWrap: 'wrap' }}
          >
            {msg.map((item) => (
              <span
                onClick={() => handleSchema('label', item?.name, item?.chart_id, item.type)}
                id={'msg_text_pre_' + id}
                className="cstm-chatbox-chip"
                style={{
                  marginLeft: me ? '35px' : '28px',
                  textAlign: me ? 'end' : 'inherit',
                  border: item?.name === labelToPlot ? '1px solid black' : '0px',
                  background: item?.name === labelToPlot ? '#4BC641' : 'black',
                  color: item?.name === labelToPlot ? '#000' : '#fff',
                }}
                key={item?.id}
              >
                {item?.name}
              </span>
            ))}
          </div>
          <p style={{ fontFamily: 'Instrument Sans,sans-serif' }}>
            Please select a data field from the below list :{' '}
          </p>
          <div
            style={{ marginLeft: '28px', marginBottom: '20px', display: 'flex', flexWrap: 'wrap' }}
          >
            {msg
              ?.filter((itm) => itm?.type !== 'string')
              .map((item) => (
                <span
                  onClick={() => handleSchema('data', item?.name, item?.chart_id, item.type)}
                  id={'msg_text_pre_' + id}
                  className="cstm-chatbox-chip"
                  style={{
                    marginLeft: me ? '35px' : '28px',
                    textAlign: me ? 'end' : 'inherit',
                    border: item?.name === dataToPlot ? '1px solid black' : '0px',
                    background: item?.name === dataToPlot ? '#4BC641' : 'black',
                    color: item?.name === dataToPlot ? '#000' : '#fff',
                  }}
                  key={item?.id}
                >
                  {item?.name}
                </span>
              ))}
            {msg?.filter((itm) => itm?.type !== 'string')?.length === 0 && (
              <p className="text-center">No data to plot</p>
            )}
          </div>
        </div>
      )}

      {msg.datasets && (
        <Box
          className="chart-container"
          style={{
            marginLeft: '28px',
            padding: '8px 0px',
            marginBottom: '0px',
            borderRadius: '0 20px 20px 20px',
          }}
        >
          <Pie data={msg} style={{ cursor: 'pointer' }} onClick={handleEnlarge} />
        </Box>
      )}
      <CModal visible={isEnlargeModal} size="lg">
        <CModalHeader
          className="customBoardModalbg cstmModalRadius"
          closeButton
          onClick={() => setIsEnlargeModal(false)}
        />
        <CModalBody className="customBoardModalbg cstmModalRadiusBottom">
          <Pie data={msg} />
        </CModalBody>
      </CModal>
    </div>
  )
}

Message.propTypes = {
  me: PropTypes.bool,
  msg: PropTypes.string,
  id: PropTypes.string,
}
