import React from 'react'
import ai3 from '../../../assets/adminBoard/aiLogo.svg'
import user from '../../../assets/images/avatars/user.png'
import PropTypes from 'prop-types'

export default function Message({ me, msg, id }) {
  return (
    <div
      className="chat-panel"
      style={{
        backgroundColor: !me ? '#5f605f41' : 'transparent',
        color: !me ? '#01313c ' : 'black',
      }}
    >
      <div className="chatIconDiv">
        <div className="chatIcon">
          {me && <img src={user} width="30" alt="" />}
          {!me && <img src={ai3} width="30" alt="" />}
        </div>
      </div>
      <p
        id={'msg_text_pre_' + id}
        className="cstm-msg"
        style={{
          marginLeft: me ? '35px' : '28px',
          color: '#ffff',
          wordBreak: 'break-word',
        }}
      >
        {msg?.trim()}
      </p>
    </div>
  )
}

Message.propTypes = {
  me: PropTypes.bool,
  msg: PropTypes.string,
  id: PropTypes.string,
}
