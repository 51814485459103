import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './scss/_restructure.scss'
import './scss/_admin.scss'
import './scss/_all.scss'
import './scss/_leftsidebar.scss'
// import './scss/_variablesLanding.scss'
// import './scss/_variableslanding.scss'
import LandingPage from './views/landingPage/Index'
import NewLandingPage from './views/NewLandingPage'
import DashboardLayout from './views/UserDashboard/dashboardLayout'
import Layout from './views/CustomBoard/layout'
import CustomBoardChartEmbed from './views/CustomBoard/Chart/chartEmbed'
import CustomBoard from './views/CustomBoard/index'
import CustomBoardDatalog from './views/CustomBoard/Datalog/index.js'
import CustomBoardChart from './views/CustomBoard/Chart/dashboardLayout.js'
import EmbedDashboard from './views/UserDashboard/embedDashboard'
import CustomBoardEmbedDashboard from './views/CustomBoard/Chart/embedDashboard'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout';

// Pages
const Index = React.lazy(() => import('./views/ChatGPT/Index'))
const NewLogin = React.lazy(() => import('./views/CustomBoard/Login/index.js'))

const QueryIndex = React.lazy(() => import('./views/QueryGPT/Index'))
const DocumentIndex = React.lazy(() => import('./views/DocumentGPT/Index'))
const Login = React.lazy(() => import('./views/account/Login'))
const ChartEmbed = React.lazy(() => import('./views/UserDashboard/chartEmbed'))
const App = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route exact path="/" name="Default" element={<LandingPage />} /> */}
          <Route exact path="/" name="Default" element={<NewLandingPage />} />
          <Route exact path="/user-dashboard" name="Dashboard" element={<DashboardLayout />} />
          <Route exact path="/user-dashboard/:id" name="Dashboard" element={<DashboardLayout />} />
          <Route
            exact
            path="/embed-dashboard/:id"
            name="EmbedDashboard"
            element={<EmbedDashboard />}
          />
          {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
          {/* <Route
            exact
            path="/login"
            name="Login Page"
            element={isAuthenticated ? <Navigate to={'/dashboard'} /> : <Login />}
          /> */}
          <Route
            exact
            path="/datasetCategorySearch"
            name="Dataset category Page"
            element={<Index />}
          />
          <Route exact path="/ai-query-gpt" name="AI Query Page" element={<QueryIndex />} />
          <Route exact path="/doc-gpt" name="Document Search" element={<DocumentIndex />} />
          {/* <Route
            path="*"
            name="Home"
            element={isAuthenticated ? <DefaultLayout /> : <Navigate to={'/login'} />}
          /> */}
          <Route exact path="/embedChart/:id" element={<ChartEmbed />} />
          <Route exact path="/login" name="Login" element={<NewLogin />} />
        </Routes>
        <Layout>
          <Routes>
            <Route exact path="/mydashboard" name="Dashboard" element={<CustomBoard />} />
            <Route exact path="/mydatalog" name="Datalog" element={<CustomBoardDatalog />} />
            <Route exact path="/mychart" name="Chart" element={<CustomBoardChart />} />
            <Route exact path="/mychart/:id" name="Dashboard" element={<CustomBoardChart />} />
            <Route
              exact
              path="/mychart-embbed/:id"
              name="Dashboard"
              element={<CustomBoardChartEmbed />}
            />
            <Route
              exact
              path="/mychartembed-dashboard/:id"
              name="EmbedDashboard"
              element={<CustomBoardEmbedDashboard />}
            />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
