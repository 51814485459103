import { store } from 'src/redux/store'

export const getHeaders = () => {
  const token = store.getState().auth?.userData?.token || ''
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

export const getFormHeaders = () => {
  const token = store.getState().auth?.userData?.token || ''

  return {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  }
}

export function capitalizeFirstLetter(string = '') {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}
