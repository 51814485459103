import React, { useEffect } from 'react'
import headfon from '../../assets/images/avatars/Helpdesk.svg'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDataSetCategoryList } from 'src/redux/dataSetCategory/actions'
import { CSpinner } from '@coreui/react'
import NoAuthHeader from 'src/components/NoAuthHeader'
import landingLogo from '../../assets/images/avatars/landingai.png'
export default function NewLandingPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDatasetCategoryLoading, datasetCategories } = useSelector(
    (state) => state.dataSetCategory,
  )

  useEffect(() => {
    dispatch(getDataSetCategoryList(navigate))
  }, [])
  return (
    <>
      <NoAuthHeader />
      <div className="container py-5">
        <div className="banner-area">
          <img src={landingLogo} alt="" className="bnr-img" />
          <h1>
            Welcome to <span className="text-primary">Whizz!</span>
          </h1>
          <h5>
            Our Conversational AI products are designed to understand, process, and respond to
            humans in a natural and engaging way. Whizz can quickly learn large datasets and start
            talking to users, prospects, and customers, providing a superior conversational
            experience and amplifying your businesse&apos;s effectiveness online.
          </h5>
        </div>
      </div>

      <div className="contentarea">
        {isDatasetCategoryLoading ? (
          <CSpinner />
        ) : (
          <>
            <div className="row g-4">
              {(Array.isArray(datasetCategories) ? datasetCategories : []).map(
                (categoryObj, categoryIndex) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div
                          className="contentboxes"
                          onClick={(event) => {
                            event.preventDefault()
                            dispatch({
                              type: 'SET_FRONTEND_CATEGORY_PAGE_CATEGORY_TITLE',
                              payload:
                                typeof categoryObj?.label === 'string' ? categoryObj.label : '',
                            })
                            dispatch({
                              type: 'SET_FRONTEND_CATEGORY_PAGE_CATEGORY_ID',
                              payload: !Number.isNaN(parseInt(categoryObj?.id))
                                ? parseInt(categoryObj.id)
                                : 0,
                            })
                            navigate({
                              pathname: '/datasetCategorySearch',
                            })
                          }}
                        >
                          <div className="icons">
                            <img src={headfon} alt="" />
                          </div>
                          <h5>{typeof categoryObj?.label === 'string' ? categoryObj.label : ''}</h5>
                          <p>
                            {typeof categoryObj?.description === 'string'
                              ? categoryObj.description
                              : ''}
                          </p>
                        </div>
                      </div>
                    </>
                  )
                },
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
