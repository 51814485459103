import React, { useEffect, useState } from 'react'
import UserDashboard from './dashboard'
import DashboardSideBar from './dashboardSideBar'
import DashboardHeader from './dashboardHeader'
import { CloseRounded, Forum } from '@mui/icons-material'
import Input from '../CustomGraphBot/input'
import Messages from '../CustomGraphBot/messages'
import axios from 'axios'

const DashboardLayout = () => {
  const [messages, setMessages] = useState([
    {
      msg: "Hello! It's great to have you here. How can I assist you today?",
      me: false,
      _id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
      id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
    },
  ])
  const [dashboardId, setDashboardId] = useState(null)
  const [isBotVisible, setIsBotVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatloading] = useState(false)
  const [streamingStatus, setStreamingStatus] = useState(false)
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = useState('HELPDESK')
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [runningStream, setRunningStream] = useState(null)

  function addMessage(msg) {
    setMessages((prev) => {
      console.log('message ', msg)
      // Check if the message with the given ID already exists in the array
      const existingIndex = prev.findIndex((item) => item.id === msg.id)

      if (existingIndex !== -1) {
        // If the message exists, update it with the new message
        const updatedMessages = [...prev]
        updatedMessages[existingIndex].msg = updatedMessages[existingIndex].msg + msg?.msg
        return updatedMessages
      } else {
        // If the message doesn't exist, add it as a new message
        return [...prev, msg]
      }
    })
  }

  function toggleLoading(value) {
    setLoading(value)
  }

  useEffect(() => {
    getAllDashboardsByDBid('64d4816c2b8501541c441cd1')
  }, [])

  const getAllDashboardsByDBid = (dbId) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/?database_id=` + dbId)
      .then((resp) => {
        const modifiedData = resp?.data?.map((nav) => ({
          ...nav,
          isEditable: false,
        }))
        if (resp?.status === 200) {
          console.log('Resppp ', resp.data[0].id)
          setDashboardId(resp.data[0].id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <DashboardSideBar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <DashboardHeader />
        <div className="body flex-grow-1 px-3 mx-2 mt-2">
          <UserDashboard />
        </div>
        {/* <AppFooter /> */}
      </div>
      <>
        {isBotVisible ? (
          <div
            style={{ position: 'fixed', right: '17px', bottom: '135px' }}
            // className="botAnimie"
          >
            <div className="main_cont" style={{ height: '75vh', width: '600px' }}>
              <div className="bot-header d-flex justify-content-between">
                <p className="bot-header-title">
                  {' '}
                  Welcome to Whizz&apos;s World: Let&apos;s Chat!!
                </p>

                <CloseRounded
                  onClick={() => {
                    setIsBotVisible(false)
                    const botDiv = document.querySelector('.bot-div') // Give your div a class name
                    botDiv.classList.add('slide-out')
                    // After the animation is complete, remove the element from the DOM
                    botDiv.addEventListener('animationend', () => {
                      botDiv.remove()
                    })
                    setMessages([
                      {
                        msg: "Hello! It's great to have you here. How can I assist you today?",
                        me: false,
                        _id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                        id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                      },
                    ])
                  }}
                  style={{ margin: '18px 14px 0px 0px', fontSize: '26px', cursor: 'pointer' }}
                />
              </div>
              <div
                className="inner_cont"
                onWheel={() => setIsUserScroll(true)}
                style={{
                  height: '100%',
                  overflowY: 'auto',
                  borderRadius: '10px',
                  paddingRight: '8px',
                  // marginTop: '10px',
                  background: '#EEEDEF',
                }}
              >
                <div style={{ display: messages.length !== 0 ? 'block' : 'none' }}>
                  <Messages
                    show={loading}
                    messages={messages}
                    setMessages={setMessages}
                    toggleLoading={toggleLoading}
                    addMessage={addMessage}
                    streamingStatus={streamingStatus}
                    setChatloading={setChatloading}
                  />
                </div>
              </div>
              {messages.length > 0 && (
                <Input
                  maxwidth
                  chatLoading={chatLoading}
                  setChatloading={setChatloading}
                  toggleLoading={toggleLoading}
                  addMessage={addMessage}
                  setIsUserScroll={setIsUserScroll}
                  dashboardId={dashboardId}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="botShortcut" onClick={() => setIsBotVisible(true)}>
            <Forum style={{ margin: ' 10px 4px 0px 18px' }} />
          </div>
        )}
      </>
    </div>
  )
}

export default DashboardLayout
