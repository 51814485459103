import React, { useEffect, useRef, useState } from 'react'
import sendImage from '../../../assets/adminBoard/send.svg'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

export default function Input({
  addMessage,
  toggleLoading,
  chatLoading,
  categoryId,
  setChatloading,
  subText,
  maxwidth,
  setStreamingStatus,
  setRunningStream,
  setIsUserScroll,
  inputFromMainScreen,
}) {
  const [input, setInput] = useState('')
  const [, setEventSource] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const inputChatRef = useRef(null)
  useEffect(() => {
    if (chatLoading === false) {
      inputChatRef.current.focus()
    }
  }, [chatLoading])
  useEffect(() => {
    let session_id = uuid()
    setSessionId(session_id)
  }, [categoryId])

  function handleInput() {
    if (!categoryId) {
      toast.error('Before we proceed with any questions, please select an item from the list !! ')
      return
    }

    // if (inputFromMainScreen) {
    //   sessionId = uuid()
    //   localStorage.setItem('sessionId', sessionId)
    // } else {
    //   sessionId = localStorage.getItem('sessionId')
    // }
    var id = uuid()
    addMessage({
      msg: input,
      me: true,
      _id: id,
      id: id,
      category_id: categoryId,
    })
    setInput('')
    toggleLoading(true)
    setIsUserScroll(false)
    setChatloading(true)
    const encodeInput = encodeURIComponent(input)
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_ENDPOINT}/conversion?question=${encodeInput}&category_id=${categoryId}&session_id=${sessionId}`,
    )
    setRunningStream(eventSource)

    var updateid = uuid()

    eventSource.onmessage = (event) => {
      setStreamingStatus(true)

      // const response = JSON.parse(event)
      toggleLoading(false)

      addMessage({
        msg: event.data,
        me: false,
        _id: updateid,
        id: updateid,
        category_id: categoryId,
      })
    }
    eventSource.onerror = (error) => {
      setStreamingStatus(false)
      setChatloading(false)

      console.error('Error with EventSource:', error)
      eventSource.close()
    }
    setEventSource(eventSource)

    return () => {
      if (eventSource) {
        eventSource.close()
      }
    }
  }
  return (
    <div className="send">
      <input
        type="text"
        disabled={chatLoading}
        autoFocus={true}
        ref={inputChatRef}
        onKeyDown={(e) => e.keyCode === 13 && input !== '' && handleInput()}
        onChange={(e) => setInput(e.target.value)}
        value={input}
        className="send-input"
        placeholder="Send a message"
      />
      <div
        disabled={chatLoading || input.length === 0}
        onClick={() => handleInput()}
        className="send-icon"
      >
        <img src={sendImage} style={{ cursor: 'pointer' }} alt="" />
      </div>
    </div>
  )
}

Input.propTypes = {
  addMessage: PropTypes.func,
  toggleLoading: PropTypes.func,
  chatLoading: PropTypes.bool,
  category: PropTypes.string,
}
