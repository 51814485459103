/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'
import ai3 from '../../assets/adminBoard/aiLogo.svg'
import home from '../../assets/images/avatars/home.svg'
import refresh from '../../assets/images/avatars/refresh.svg'
import stop from '../../assets/images/avatars/stop.svg'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import Message from './message'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Messages({
  messages,
  show,
  cstmBoard,

  addMessage,
}) {
  const scrollRef = useRef(null)
  const viewRef = useRef(null)

  const [chart_id, setChart_id] = useState('')
  useEffect(updateScroll, [messages])

  useEffect(() => {
    // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      updateScroll()
    })
    resizeObserver.observe(scrollRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [messages])

  function updateScroll() {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Fragment>
      <div className="msg_cont" style={{ overflowY: 'auto' }}>
        <div className="message-container" style={{ marginTop: '0px' }}>
          <div ref={viewRef}></div>

          {messages.map((item) => {
            return (
              <div
                ref={scrollRef}
                key={item._id}
                style={{
                  marginRight: !item.me ? (item.msg.datasets ? '0px' : '140px') : '',
                  marginLeft: item.me ? '140px' : '',
                  marginBottom: '16px',
                }}
              >
                <Message
                  id={item._id}
                  cstmBoard={cstmBoard}
                  me={item.me}
                  msg={item.msg}
                  _id={item._id}
                  key={item._id}
                  chart_id={chart_id}
                  animate={item._id === messages[messages.length - 1]._id}
                  addMessage={addMessage}
                />
              </div>
            )
          })}
        </div>
        {show && (
          <div className="chat-panel-loading" style={{ background: 'transparent' }}>
            <div className="chatIconDiv">
              <div className="chatIcon">
                <img src={ai3} width="30" alt="" />
              </div>
            </div>
            <pre className="spinner-container">
              <ImpulseSpinner frontColor="white" size={25} />
            </pre>
          </div>
        )}
      </div>
    </Fragment>
  )
}

Messages.propTypes = {
  messages: PropTypes.array,
  show: PropTypes.bool,
  setMessages: PropTypes.array,
  toggleLoading: PropTypes.func,
  category: PropTypes.string,
}
