/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { CRow, CCol, CFormInput } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import { useNavigate } from 'react-router-dom'
import { documentUpload } from 'src/redux/services/documentUpload'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/auth/actions'
// import { RangeSlider } from '../../components'
import CIcon from '@coreui/icons-react'
import { cilTrash, cilPlus, cilAlbum, cilAddressBook } from '@coreui/icons'
import LoaderImg from '../../assets/images/avatars/loader.gif'
import { getDataSetCategoryList } from '../../redux/dataSetCategory/actions'
import * as Yup from 'yup'
import CustomModal from '../../components/CustomModal'
import uuid from 'react-uuid'
import { Configuration } from 'src/utility/configuration'
import { axiosPOST } from 'src/utility/apis'
import { siteMap } from 'src/redux/services/siteMap'
import AddUrl from './AddUrl'
import addDoc from '../../assets/images/avatars/add-document-icon.svg'
import { getDocumentList } from 'src/redux/documentManagment/actions'
/**
 * Add modal document processing
 */
const AddModal = ({
  visible,
  closeModal,
  isEdit,
  editData,
  setShowProgressbar,
  setUploadPercentage,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [docFile, setDocFile] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [fileError, setFileError] = useState('')
  const [urlFile, setUrlFile] = useState([])
  const [fileType, setFileType] = useState('doc')
  const inputRef = useRef(null)
  const prevTemperature = !Number.isNaN(parseFloat(editData?.temperature))
    ? parseFloat(editData.temperature)
    : 0
  const [tempatureVal, setTempatureVal] = useState(prevTemperature)
  const [isFileUploading, setIsFileUploading] = useState(false)

  const [formErrorObj, setFormErrorObj] = useState(undefined)

  const [sourceType, setSourceType] = useState('audio')
  // const [audioFile, setAudioFile] = useState(null)
  const [sitemapUrl, setSiteMapUrl] = useState('')
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(false)

  useEffect(() => {
    if (isEdit) {
      setTempatureVal(prevTemperature)
    }
    // eslint-disable-next-line
  }, [isEdit])

  useEffect(() => {
    dispatch(getDataSetCategoryList(navigate))
  }, [])

  /**
   * Validate form
   *
   * @param {object} inputObj Input object
   * @param {string} inputObj.docCategoryId Document category id
   * @returns {void}
   */
  const validateForm = async (inputObj) => {
    let output = undefined
    let validateSchema = undefined
    let errorMsg = ''

    try {
      validateSchema = Yup.object().shape({
        docCategoryId: Yup.string().required('This field is required'),
      })

      validateSchema.validateSync({ docCategoryId: inputObj?.docCategoryId })
    } catch (errorObj) {
      errorMsg = typeof errorObj?.message === 'string' ? errorObj.message : 'Error'
      output = { ...output, docCategoryId: errorMsg }
    }

    setFormErrorObj(output)
    if (typeof output === 'undefined') {
      return true
    }

    return false
  }

  /**
   * Form submit handle
   */

  const categoryApi = async () => {
    console.log(sourceType)
    let categoryId = uuid()
    const url = Configuration.datasetCategory.category
    const payload = {
      label:
        fileType === 'doc'
          ? docFile[0]?.name + '@@' + categoryId
          : sourceType === 'audio'
          ? urlFile[0]?.name + '@@' + categoryId
          : sitemapUrl + '@@' + categoryId,
      temperature: '0',
      description: null,
      icon: null,
      is_visible: false,
    }
    try {
      const result = await axiosPOST(url, payload)
      console.log('fileType ', fileType)
      if (fileType === 'doc') {
        documentApi(result?.id)
      } else if (fileType === 'url') {
        urlApi(result?.id)
      }
    } catch (err) {
      toast.error('Something went wrong !!')
    }
  }

  const documentApi = async (categoryId) => {
    let fileObj
    const form_data = new FormData()
    const files = docFile ? docFile : []

    for (fileObj of files) {
      form_data.append('files', fileObj)
    }

    form_data.append('temperature', tempatureVal)
    form_data.append('categories', parseInt(categoryId))

    setLoading(true)

    setIsAddButtonDisable(true)

    setTimeout(() => {
      closeModal()
      setShowProgressbar(true)
    }, 800)
    setTimeout(() => {
      dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
    }, 3000)
    try {
      await documentUpload(form_data, setUploadPercentage).then((res) => {
        if (res) {
          // toast(res.message, 'success')
          if (files.length > 0) {
            setIsFileUploading(false)
          }
          setTempatureVal(0)
          setIsUpload(!isUpload)
          closeModal()

          setShowProgressbar(false)
          dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
          setLoading(false)
        } else if (typeof res?.message === 'string' && res.message.trim() !== '') {
          // toast(res.message, 'success')
          if (files.length > 0) {
            setIsFileUploading(true)
          }
          setIsUpload(!isUpload)
          setLoading(false)
          setTempatureVal(0)
          closeModal()
          dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
          setShowProgressbar(false)
        } else if (res?.status === 422) {
          toast.error('Failed to add.')
          setIsFileUploading(false)
          setShowProgressbar(false)
        } else if (res?.status === 401) {
          toast.error('Something went wrong, Please try again later.')
          setIsFileUploading(false)
          setShowProgressbar(false)
          // dispatch(logout())
          // navigate('/login')
        } else {
          toast('Document upload failed', 'error')
          setIsFileUploading(false)
          setShowProgressbar(false)
        }
      })
    } catch (err) {
      setShowProgressbar(false)
      console.log(err)
      toast.error('Document upload failed')
    }
  }

  const urlApi = async (cId) => {
    if (sourceType !== 'audio') {
      if (!(sitemapUrl.includes('http') || sitemapUrl.includes('https'))) {
        setFileError('Enter a valid URL')
        return
      }
    }

    setLoading(true)

    setIsAddButtonDisable(true)
    setTimeout(() => {
      closeModal()
      dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
      if (sourceType === 'audio') {
        setShowProgressbar(true)
      }
    }, 800)

    try {
      setIsAddButtonDisable(true)
      const res = await siteMap(cId, setUploadPercentage, sitemapUrl, sourceType, urlFile[0])

      if (res?.success) {
        toast(res?.message, 'success')
        closeModal()
        dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
        setShowProgressbar(false)

        setLoading(false)
        setIsAddButtonDisable(false)
      } else {
        setIsAddButtonDisable(false)
        dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
        setShowProgressbar(false)

        toast(res?.message, 'error')
        setLoading(false)
      }
    } catch (error) {
      setIsAddButtonDisable(false)

      setShowProgressbar(false)

      console.error('Error:', error)
      toast.error('An error occurred')
    }
  }

  const handleSubmit = async (event) => {
    setIsAddButtonDisable(true)
    event.preventDefault()
    event.stopPropagation()
    categoryApi()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 2px gray' // Highlight the drop area when dragging over
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border when leaving
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border

    const droppedFiles = e.dataTransfer.files
    processDroppedFiles(droppedFiles)
  }

  const processDroppedFiles = (files) => {
    // Handle dropped files similar to the file input's onChange event
    let errMsg = ''
    let totalFileSize = 0
    const acceptTypes = ['pdf', 'docx', 'doc']
    let newFiles = []

    if (files.length > 0) {
      const lastFile = files[files.length - 1]

      const filePortions = lastFile.name.split('.')
      const fileExtension = filePortions.pop().toLowerCase()

      if (!acceptTypes.includes(fileExtension)) {
        errMsg = 'Please upload either pdf, docx, and doc'
      } else {
        if (!Number.isNaN(parseFloat(lastFile.size))) {
          totalFileSize = parseFloat(lastFile.size)
        }

        if (totalFileSize > 5000000) {
          errMsg = 'Please select a file smaller than 5 MB'
        } else {
          newFiles.push(lastFile)
        }
      }
    }

    if (errMsg.trim() === '') {
      setFileError('')
      setDocFile(newFiles)
    } else {
      setFileError(errMsg)
    }
  }

  useEffect(() => {
    if (docFile?.length === 0 && urlFile?.length === 0 && sitemapUrl?.length === 0) {
      setIsAddButtonDisable(true)
    } else {
      setIsAddButtonDisable(false)
    }
  }, [docFile, urlFile, sitemapUrl])

  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        headerText={'Add Document / URL'}
        onSubmit={handleSubmit}
        okButtonLabel={'Add'}
        isAddButtonDisable={isAddButtonDisable}
        okButtonDisabled={loading}
        cancelButtonLabel="Cancel"
        cancelButtonFn={(event) => {
          event.preventDefault()
          closeModal()
        }}
      >
        <>
          <div className="d-flex mb-3">
            <div className="form-check" style={{ marginRight: '16px', cursor: 'pointer' }}>
              <input
                className="form-check-input"
                style={{ border: '1px solid white' }}
                type="radio"
                name="fileType"
                onChange={() => {
                  setFileType('doc')
                  setUrlFile([])
                  setFileError('')
                  setSourceType('audio')
                }}
                value="document"
                id="flexRadioDefault3"
                // defaultChecked
                checked={fileType === 'doc'}
              />
              <label className="form-check-label">Document</label>
            </div>
            <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                type="radio"
                name="fileType"
                style={{ border: '1px solid white' }}
                onChange={() => {
                  setFileType('url')
                  setDocFile([])
                  setFileError('')
                  setSourceType('audio')
                }}
                value="urls"
                id="flexRadioDefault1"
                checked={fileType === 'url'}
              />
              <label className="form-check-label">URL</label>
            </div>
          </div>

          {fileType === 'doc' && (
            <>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div className="mt-5">
                    {/* <CFormLabel>Document</CFormLabel> */}
                    <div
                      className="d-grid justify-content-center mb-4 pt-4 pb-2"
                      style={{
                        cursor: 'pointer',
                        border: 'dashed 1px white',
                        borderRadius: '18px',
                      }}
                      onClick={() => inputRef.current.click()}
                      onDragOver={(e) => handleDragOver(e)}
                      onDragLeave={(e) => handleDragLeave(e)}
                      onDrop={(e) => handleDrop(e)}
                    >
                      <div className="d-grid justify-content-center">
                        <img src={addDoc} />
                      </div>
                      <p className="mt-3 px-3">
                        Drag and drop files here, or click to select files.
                      </p>
                    </div>

                    <CFormInput
                      ref={inputRef}
                      style={{ display: 'none' }}
                      required
                      className="mb-2"
                      placeholder="Upload file"
                      name="doc"
                      type="file"
                      multiple={false}
                      accept=".pdf,.docx,.doc"
                      onChange={(event) => {
                        setFileError('')
                        event.preventDefault()
                        let prevFileObj
                        let errMsg = ''
                        let fileObj
                        let totalFileSize = 0
                        let filePortions = []
                        const acceptTypes = ['pdf', 'docx', 'doc']
                        let files = docFile ? docFile : []

                        fileObj = event?.target?.files?.[0]

                        filePortions = (
                          typeof fileObj?.name === 'string' ? fileObj.name : ''
                        ).split('.')
                        filePortions = filePortions.pop()
                        filePortions = (
                          typeof filePortions === 'string' ? filePortions : ''
                        ).toLowerCase()
                        if (!acceptTypes.includes(filePortions)) {
                          errMsg = 'Please upload either pdf, docx, and doc'
                        }

                        if (files.length + 1 > 5) {
                          errMsg = 'Only 5 files accepted'
                          files = []
                        }

                        for (prevFileObj of files) {
                          if (!Number.isNaN(parseFloat(prevFileObj?.size))) {
                            totalFileSize += parseFloat(prevFileObj?.size)
                          }
                        }

                        if (!Number.isNaN(parseFloat(fileObj?.size))) {
                          totalFileSize += parseFloat(fileObj?.size)
                        }

                        if (errMsg.trim() === '' && totalFileSize > 5000000) {
                          errMsg = 'Please select a file smaller than 5 MB'
                        }

                        if (typeof errMsg === 'string' && errMsg.trim() !== '') {
                          setFileError(errMsg)
                        } else {
                          setFileError('')
                          let singleFile = []

                          singleFile.push(fileObj)
                          setDocFile(singleFile)
                        }
                      }}
                    />
                    <span style={{ color: 'red' }}>{fileError}</span>

                    <p style={{ fontSize: '14px', color: 'gray' }}>
                      Please ensure that your file is in either PDF or DOC format. Maximum of the
                      total file should be 5 MB. A user can only upload one file.
                    </p>
                  </div>
                </CCol>
              </CRow>
              {docFile.length > 0 ? (
                <>
                  {docFile.map((itemObj, itemIndex) => {
                    return (
                      <>
                        <CRow className="my-2" key={'documentUploadRow' + itemIndex}>
                          <CCol md={10} className="ps-lg-2 text-truncate">
                            {typeof itemObj?.name === 'string' ? itemObj.name : ''}
                          </CCol>
                          <CCol
                            md={2}
                            className="d-flex justify-content-md-end justify-content-sm-start"
                          >
                            <span
                              className={`${isFileUploading ? 'd-none' : ''}`}
                              onClick={(event) => {
                                event.preventDefault()
                                let prevFiles = [...docFile]
                                prevFiles.splice(itemIndex, 1)
                                setDocFile(prevFiles)
                              }}
                            >
                              <CIcon
                                icon={cilTrash}
                                color="danger"
                                size="lg"
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                              />
                            </span>
                            <span
                              className={`${
                                !isFileUploading ? 'd-none' : ''
                              } d-flex justify-content-md-end justify-content-sm-start`}
                            >
                              <img src={LoaderImg} alt="Loader" className="w-50" />
                            </span>
                          </CCol>
                        </CRow>
                      </>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {fileType === 'url' && (
            <>
              <AddUrl
                sourceType={sourceType}
                sitemapUrl={sitemapUrl}
                setSiteMapUrl={setSiteMapUrl}
                setSourceType={setSourceType}
                setIsAddButtonDisable={setIsAddButtonDisable}
                setUrlFile={setUrlFile}
                urlFile={urlFile}
                setFileError={setFileError}
                fileError={fileError}
              />
              {urlFile.length > 0 ? (
                <>
                  {urlFile.map((itemObj, itemIndex) => {
                    return (
                      <>
                        <CRow className="my-2" key={'documentUploadRow' + itemIndex}>
                          <CCol md={10} className="ps-lg-2 text-truncate">
                            {typeof itemObj?.name === 'string' ? itemObj?.name : ''}
                          </CCol>
                          <CCol
                            md={2}
                            className="d-flex justify-content-md-end justify-content-sm-start"
                          >
                            <span
                              className={`${isFileUploading ? 'd-none' : ''}`}
                              onClick={(event) => {
                                event.preventDefault()
                                let prevFiles = [...urlFile]
                                prevFiles.splice(itemIndex, 1)
                                setUrlFile(prevFiles)
                              }}
                            >
                              <CIcon
                                icon={cilTrash}
                                color="danger"
                                size="lg"
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                              />
                            </span>
                            <span
                              className={`${
                                !isFileUploading ? 'd-none' : ''
                              } d-flex justify-content-md-end justify-content-sm-start`}
                            >
                              <img src={LoaderImg} alt="Loader" className="w-50" />
                            </span>
                          </CCol>
                        </CRow>
                      </>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </CustomModal>
    </>
  )
}

AddModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default AddModal
