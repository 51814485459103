import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalContent,
  CSpinner,
} from '@coreui/react'

/**
 * Create custom modal
 */
const CustomModal = ({
  visible = false,
  closeModal,
  headerText = '',
  onSubmit = undefined,
  okButtonLabel = '',
  okButtonDisabled = false,
  okButtonFn = undefined,
  cancelButtonLabel = '',
  cancelButtonDisabled = false,
  isCustomBoard,
  isAddButtonDisable,

  cancelButtonFn = (event) => {
    event.preventDefault()
  },
  children = <></>,
  modalSize = 'md',
}) => {
  return (
    <>
      <CModal
        alignment="center"
        visible={visible}
        onClose={closeModal}
        size={modalSize}
        backdrop="static"
      >
        <CModalContent className={`${isCustomBoard ? 'customModalContent' : ''}`}>
          {typeof headerText === 'string' && headerText.trim() !== '' ? (
            <CModalHeader
              closeButton={isCustomBoard ? false : true}
              className={`${
                isCustomBoard ? 'customBoardModalbg cstmModalRadius' : 'normalModalbg'
              }`}
            >
              <CModalTitle
                style={{
                  color: isCustomBoard ? 'white' : 'black',
                }}
              >
                {headerText}
              </CModalTitle>
            </CModalHeader>
          ) : (
            <></>
          )}
          <form
            noValidate="noValidate"
            onSubmit={onSubmit}
            className={`${
              isCustomBoard ? 'customBoardModalbg cstmModalRadiusBottom' : 'normalModalbg'
            }`}
          >
            <CModalBody style={{ minHeight: isCustomBoard && '388px' }}>{children}</CModalBody>
            <CModalFooter style={{ borderTop: '0px' }}>
              {typeof cancelButtonLabel === 'string' && cancelButtonLabel.trim() !== '' ? (
                <>
                  <CButton
                    className={isCustomBoard && 'btn btn-secondary btn-sm me-2 text-white px-4'}
                    type="button"
                    color="secondary"
                    onClick={cancelButtonFn}
                    disabled={cancelButtonDisabled}
                    // disabled={isCustomBoard ? isAddButtonDisable : cancelButtonDisabled}
                  >
                    {cancelButtonLabel}
                  </CButton>
                </>
              ) : (
                <></>
              )}
              {typeof okButtonLabel === 'string' && okButtonLabel.trim() !== '' && onSubmit ? (
                <>
                  <CButton
                    className={isCustomBoard && 'btn btn-primary btn-sm me-2 text-white px-4'}
                    type="submit"
                    color="primary"
                    disabled={isCustomBoard ? isAddButtonDisable : cancelButtonDisabled}
                    // disabled={okButtonDisabled}
                  >
                    {/* {isAddButtonDisable && isCustomBoard && (
                      <CSpinner size="sm" style={{ marginRight: '8px' }} />
                    )} */}

                    {typeof okButtonLabel === 'string' ? okButtonLabel : 'Save'}
                  </CButton>
                </>
              ) : (
                <></>
              )}
              {typeof okButtonLabel === 'string' && okButtonLabel.trim() !== '' && okButtonFn ? (
                <>
                  <CButton
                    className={isCustomBoard && 'btn btn-primary btn-sm me-2 text-white px-4'}
                    type="button"
                    onClick={okButtonFn}
                    // disabled={okButtonDisabled}
                    disabled={isCustomBoard ? isAddButtonDisable : cancelButtonDisabled}
                  >
                    {/* {isAddButtonDisable && <CSpinner size="sm" style={{ marginRight: '8px' }} />} */}
                    {typeof okButtonLabel === 'string' ? okButtonLabel : 'Save'}
                  </CButton>
                </>
              ) : (
                <></>
              )}
            </CModalFooter>
          </form>
        </CModalContent>
      </CModal>
    </>
  )
}

export default CustomModal
