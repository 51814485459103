import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { Configuration } from 'src/utility/configuration'
import { toast } from '../../helpers/toast'
import { logout } from '../auth/actions'

export const ActionTypes = {
  LOADER_START: 'LOADER_STARTS',
  LOADER_STOP: 'LOADER_STOPS',
  SET_DOCUMENT_LIST: 'SET_DOCUMENT_LIST',
}

export const getDocumentList = (navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START })

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/${Configuration.documentManagement.document}`, {
        headers: getHeaders(),
      })
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DOCUMENT_LIST,
          payload: response,
        })

        dispatch({ type: ActionTypes.LOADER_STOP })
      })
      .catch((error) => {
        if (error?.response?.request?.status === 401) {
          toast('Your session has expired. Please login to pick up where you left off.', 'error')
          // dispatch(logout())
          // navigate('/login')
        }
        dispatch({ type: ActionTypes.LOADER_STOP })
      })
  }
}
