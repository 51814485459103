import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getDataLogList } from 'src/redux/dataLog/actions'

export default function CustomBoardDatalog() {
  const { data } = useSelector((state) => state.dataLog)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    loadDataLogList()
  }, [])

  const loadDataLogList = () => {
    dispatch(getDataLogList(navigate, 10, 1, '', false))
  }
  const [expandedAccordion, setExpandedAccordion] = useState(0)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false)
  }

  return (
    <>
      <div className="content-area ">
        {data?.data?.length > 0 ? (
          <>
            <div style={{ overflowY: 'auto', height: 'calc(100vh - 168px)' }}>
              {data?.data?.map((itm, i) => (
                <Accordion
                  key={itm?.id}
                  expanded={expandedAccordion === i}
                  onChange={handleChange(i)}
                  style={{ background: '#121d12', color: '#fff', marginBottom: '8px' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls={`panel${i + 1}-content`}
                    id={`panel${i + 1}-header`}
                    color="white"
                  >
                    <Typography style={{ wordBreak: 'break-word' }}>{itm?.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ wordBreak: 'break-word' }}>
                      {itm?.answers?.map((ans) => (
                        <span style={{ fontSize: '14px' }} className="mb-0" key={itm?.id}>
                          {ans?.answer}
                        </span>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '70px', fontSize: '18px' }}>No data</p>
        )}
      </div>
    </>
  )
}
