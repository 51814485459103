import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CModal,
  CModalBody,
  CModalHeader,
  // CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import logoImg from '../../assets/images/logo/cubet-logo-with-text.svg'
import miniLogo from '../../assets/images/logo/miniLogo.png'
// sidebar nav config
import AddCircle from '@mui/icons-material/AddCircle'
// import navigation from './_nav'
import { setSidebar, setSidebarUnfoldable } from 'src/redux/feature/sidebarSlice'
// import { DashboardSidebarNav } from './dashbordSideBarNav'
import CIcon from '@coreui/icons-react'
import {
  cibBuffer,
  cilCheckCircle,
  cilPen,
  cilPlus,
  cilSpeedometer,
  cilTrash,
  // cilX,
} from '@coreui/icons'
import axios from 'axios'
import { TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { KeyboardArrowDown, KeyboardArrowUp, Storage } from '@mui/icons-material'
import { toast } from 'react-toastify'

const DashboardSideBar = () => {
  const location = useLocation()

  const dispatch = useDispatch()
  const { sidebarShow, unfoldable } = useSelector((state) => state.sidebar)
  const [dashboardId, setdashboardId] = useState(null)
  const [inputPanel, setInputPanel] = useState(false)
  const [dashboardName, setDashboardName] = useState('')
  const [isCreateDashboradModal, setIsCreateDashboradModal] = useState(false)
  const [allDBArray, setAllDBArray] = useState([])
  const [selectedDBid, setSelectedDBid] = useState('')
  const [allDashboardsOfDB, setAllDashboardsOfDB] = useState([])
  const [isDashboardNav, setIsDashboardNav] = useState(false)
  const navigate = useNavigate()
  /**
   * Logout click fuction
   */
  // const onLogoutClick = () => {
  //   setLogout(true)
  // }

  useEffect(() => {
    const parts = location.pathname.split('/')
    setdashboardId(parts[parts.length - 1])
  }, [location])

  const handleCreateNew = async () => {
    // setInputPanel(true)
    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
        title: dashboardName,
        database_id: selectedDBid,
      })
      .then((response) => {
        if (response?.status === 201) {
          setIsCreateDashboradModal(false)
          getAllDashboardsByDBid(selectedDBid)
          setInputPanel(false)
        }
      })
  }

  const listAllDB = async () => {
    const dbs = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}databases/`)
    setAllDBArray(dbs?.data)
  }

  useEffect(() => {
    listAllDB()
  }, [])

  const getAllDashboardsByDBid = (dbId) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/?database_id=` + dbId)
      .then((resp) => {
        const modifiedData = resp?.data?.map((nav) => ({
          ...nav,
          isEditable: false,
        }))
        if (resp?.status === 200) {
          setAllDashboardsOfDB(modifiedData)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDeleteDashboard = async (id, dbId) => {
    let response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/` + id,
    )

    if (response?.status === 204) {
      getAllDashboardsByDBid(dbId)
    }
  }
  const handleEdit = (id, index) => {
    let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)
    setAllDashboardsOfDB((prevNavList) => {
      const updatedNavList = [...prevNavList]
      updatedNavList[indexOfEdit].isEditable = true
      return updatedNavList
    })
  }

  const handleEditDashboardName = (id, newtitle) => {
    try {
      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
          id: id,
          title: newtitle,
        })
        .then((resp) => {
          if (resp?.status === 200) {
            let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)

            setAllDashboardsOfDB((prevNavList) => {
              const updatedNavList = [...prevNavList]
              updatedNavList[indexOfEdit].title = newtitle
              updatedNavList[indexOfEdit].isEditable = false

              return updatedNavList
            })
            setDashboardName('')
          } else if (resp?.status === 422) {
            toast.error('Title already exist.')
          }
        })
        .catch((error) => {
          // Handle the error here
          if (error?.response?.status === 422) {
            toast.error('Title already exist.')
          } else {
            toast.error('Something went wrong')
          }
          console.log('An error occurred:', error)
          // You can add more specific error handling based on the error response if needed
        })
    } catch (error) {
      console.error('An error occurred:', error)
      // Handle the catch block error here if necessary
    }
  }

  return (
    <>
      <CSidebar
        className="sidebar-dashboard"
        position="fixed"
        narrow={unfoldable}
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch(setSidebar(visible))
        }}
      >
        <CSidebarBrand className="d-none d-md-flex cstm-sidebar-brand" to="/">
          {/* <div style={{ textAlign: 'center' }}>EWMB LOGO</div> */}

          <img
            src={unfoldable ? miniLogo : logoImg}
            alt="Cubet"
            onClick={() => navigate('/')}
            width={100}
            style={{ cursor: 'pointer', padding: unfoldable ? '8px' : '0' }}
          />
        </CSidebarBrand>
        <CSidebarNav className="sidebar-nav-dashboard">
          {!unfoldable && (
            <>
              {inputPanel && (
                <div
                  className="d-flex cstm-sidebar-new"
                  style={{ overflow: 'hidden', color: '#4BC641' }}
                >
                  <span style={{ marginLeft: '16px' }}>
                    <CIcon icon={cilSpeedometer} />
                  </span>
                  <span style={{ width: '170px', padding: '0px 8px' }}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ style: { color: 'black' } }}
                      onChange={(e) => setDashboardName(e.target.value)}
                      variant="standard"
                    />
                  </span>
                  <span style={{ cursor: 'pointer' }} onClick={handleCreateNew}>
                    <CIcon icon={cilCheckCircle} style={{ marginRight: '8px' }} />
                  </span>

                  <span style={{ cursor: 'pointer' }} onClick={() => setInputPanel(false)}>
                    <CIcon icon={cilTrash} style={{ marginRight: '8px' }} />
                  </span>
                </div>
              )}
            </>
          )}
          <SimpleBar>
            <CNav
              style={{
                overflow: 'hidden',
                justifyContent: unfoldable ? 'center' : 'inherit',
                padding: unfoldable ? '0' : '0',
              }}
            >
              <CNavItem>
                {allDBArray.map((nav, index) => (
                  <CNavLink
                    onClick={() => getAllDashboardsByDBid(nav?.id)}
                    vertical
                    active={dashboardId === nav?.id ? true : false}
                    key={nav?.id}
                    style={{
                      overflow: 'hidden',
                      cursor: 'pointer',
                      paddingLeft: '16px',
                      color: dashboardId === nav?.id ? 'white' : '#4BC641',
                      justifyContent: 'center',
                    }}
                  >
                    <CIcon icon={cibBuffer} />
                    {!unfoldable && (
                      <>
                        <span
                          style={{
                            width: '175px',
                            marginLeft: '8px',
                            marginRight: '8px',
                            maxWidth: '175px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontWeight: '500',
                            color: '#4BC641',
                          }}
                          onClick={() => setIsDashboardNav(!isDashboardNav)}
                        >
                          {nav?.database}
                        </span>
                        {!isDashboardNav ? (
                          <span onClick={() => setIsDashboardNav(true)}>
                            <KeyboardArrowDown />
                          </span>
                        ) : (
                          <span onClick={() => setIsDashboardNav(false)}>
                            <KeyboardArrowUp />
                          </span>
                        )}
                      </>
                    )}
                  </CNavLink>
                ))}
              </CNavItem>
            </CNav>

            {isDashboardNav && (
              <div className="" style={{ marginLeft: !unfoldable ? '14px' : '0' }}>
                <CNav
                  style={{ overflow: 'hidden', justifyContent: unfoldable ? 'center' : 'inherit' }}
                >
                  <CNavItem>
                    {allDashboardsOfDB.map((nav, index) => (
                      <CNavLink
                        onClick={() => navigate('/user-dashboard/' + nav?.id)}
                        vertical
                        active={dashboardId === nav?.id ? true : false}
                        key={nav?.id}
                        style={{
                          overflow: 'hidden',
                          cursor: 'pointer',
                          color: dashboardId === nav?.id ? 'white' : 'white',
                          justifyContent: 'center',
                        }}
                      >
                        <CIcon icon={cilSpeedometer} className="mr-2" />
                        {!unfoldable && (
                          <>
                            {!nav?.isEditable ? (
                              <span
                                style={{
                                  width: '135px',
                                  marginLeft: '8px',
                                  marginRight: '8px',
                                  maxWidth: '135px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {nav?.title}
                              </span>
                            ) : (
                              <TextField
                                id="standard-basic"
                                style={{ width: '135px', marginLeft: '8px', marginRight: '8px' }}
                                inputProps={{ style: { color: 'white' } }}
                                onChange={(e) => setDashboardName(e.target.value)}
                                defaultValue={nav?.title}
                                variant="standard"
                              />
                            )}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                nav?.isEditable
                                  ? handleEditDashboardName(
                                      nav?.id,
                                      dashboardName === '' ? nav?.title : dashboardName,
                                    )
                                  : handleEdit(nav?.id, index)
                              }
                            >
                              <CIcon
                                icon={nav?.isEditable ? cilCheckCircle : cilPen}
                                style={{ marginRight: '8px' }}
                              />
                            </span>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDeleteDashboard(nav?.id, nav?.database_id)}
                            >
                              <CIcon icon={cilTrash} />
                            </span>
                          </>
                        )}
                      </CNavLink>
                    ))}
                  </CNavItem>
                </CNav>
              </div>
            )}
          </SimpleBar>
        </CSidebarNav>
        <SimpleBar>
          {!unfoldable && (
            <span
              className="d-flex justify-content-center my-1"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsCreateDashboradModal(true)}
            >
              <div className="d-flex mr-2" style={{ color: '#ffff', fontWeight: '500' }}>
                <AddCircle icon={cilPlus} style={{ marginRight: '8px' }} />
                <span> Create Dashboard</span>
              </div>
            </span>
          )}
        </SimpleBar>
        <CSidebarToggler
          className="d-none d-lg-flex"
          onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
        />
      </CSidebar>
      <CModal visible={isCreateDashboradModal}>
        <CModalHeader>Create new dashboard</CModalHeader>
        <CModalBody>
          <label className="mb-1"> Enter dashboard name</label>
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Enter dashboard name"
            onChange={(e) => setDashboardName(e.target.value)}
          />
          <label className="mb-1"> Choose database</label>
          <select
            className="form-select form-control mb-4"
            onChange={(e) => {
              setSelectedDBid(e.target.value)
            }}
          >
            <option value={''}>Select database</option>
            {allDBArray.map((db) => (
              <option value={db?.id} key={db?.id}>
                {db?.database}
              </option>
            ))}
          </select>
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-secondary"
              style={{ marginRight: '8px' }}
              onClick={() => setIsCreateDashboradModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => handleCreateNew()}
            >
              Create
            </button>
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

export default React.memo(DashboardSideBar)
