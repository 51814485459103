import React, { useEffect, useState } from 'react'
import chatImage from '../../assets/adminBoard/chat.svg'
import closeChatImage from '../../assets/adminBoard/close-chat.svg'
import closeImage from '../../assets/adminBoard/close-i.svg'
import uploaddImg from '../../assets/adminBoard/uploadd.svg'
import pdfImage from '../../assets/adminBoard/pdf.svg'
import docImage from '../../assets/adminBoard/DOCUMENT.svg'
import mp3Image from '../../assets/adminBoard/mp3.svg'
import xmlImage from '../../assets/adminBoard/xmlIcon.svg'
import uploadImage from '../../assets/adminBoard/upload.svg'
import urlImage from '../../assets/adminBoard/doc.svg'
import AddModal from './AddModal'
import { useNavigate } from 'react-router-dom'
import { getDocumentList } from 'src/redux/documentManagment/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmDelete from 'src/components/ConfirmDelete'
import { axiosDELETE } from 'src/utility/apis'
import { toast } from 'react-toastify'
import { Configuration } from 'src/utility/configuration'
import { logout } from 'src/redux/auth/actions'
import Chat from './chatComponents/chat'
import { clearDocumentEditListData, getDocumentEditList } from 'src/redux/documentEdit/actions'
import TranscriptModal from './TranscriptModal'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilPen, cilSave } from '@coreui/icons'
import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
export default function CustomBoard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [allDocs, setAllDocs] = useState([])
  const { isLoading, data } = useSelector((state) => state.documentList)
  const [isDocModalVisible, setIsDocModalVisible] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [loading, setLoading] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [categoryTitle, setCategoryTitle] = useState('')
  const [showProgressbar, setShowProgressbar] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [transcriptModalVisible, setTranscriptModalVisible] = useState(false)
  const [newLabel, setNewLabel] = useState('')
  const [firstMessage, setFirstMessage] = useState(false)
  // const [categoryErrorMessage, setCategoryErrorMessage] = useState('')
  const [isDeleteButtonDisable, setIsDeleteButtonDisable] = useState(false)
  // const [currentEditId, setCurrentEditId] = useState(null)
  const { data: transcript } = useSelector((state) => state.documentEditList)

  const [documents, setDocuments] = useState([])
  const [expandChat, setExpandChat] = useState(false)
  useEffect(() => {
    if (documents?.length > 0) {
      let isEditableArray = documents?.filter((obj) => obj?.isEditable === true)
      if (isEditableArray?.length > 0) {
        return
      } else {
        let obj = documents?.filter((itm) => itm?.status === 'Processing')
        if (obj?.length > 0) {
          setTimeout(() => {
            getAllUploadedDocs()
          }, 10000)
        }
      }
    }
    // documents.forEach((item) => {
    //   console.log('item ', item)
    //   if (item?.status === 'Processing') {
    //     if (item?.isEditable === true) {
    //       return
    //     } else {
    //       setTimeout(() => {
    //         getAllUploadedDocs()
    //       }, 10000)
    //     }
    //   }
    // })
  }, [documents])

  useEffect(() => {
    if (data && documents?.length === 0) {
      setDocuments(data?.data?.data)
    } else {
      if (documents?.length > 0) {
        let isEditableArray = documents?.filter((obj) => obj?.isEditable === true)
        if (isEditableArray?.length > 0) {
          return
        } else {
          setDocuments(data?.data?.data)
        }
      }
    }
  }, [data])

  const closeModal = () => {
    setIsDocModalVisible(false)
  }
  useEffect(() => {
    if (!showProgressbar) {
      getAllUploadedDocs()
    }
  }, [showProgressbar])
  const getAllUploadedDocs = async () => {
    dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
    console.log('the data ', data)
    setAllDocs(data)
  }

  const loadWordList = () => {
    dispatch(getDocumentList(navigate, 20, 1, '', 'False', ''))
  }

  const handleDeleteFile = (dId, cId) => {
    if (cId === categoryId) {
      setCategoryId(null)
    }
    setDeleteModal(true)
    setDeleteItem(dId)
  }

  const onDelete = async () => {
    setIsDeleteButtonDisable(true)

    await axiosDELETE(`${Configuration.documentManagement.document}${deleteItem}/`)
      .then((data) => {
        setIsDeleteButtonDisable(false)
        if (data.status === 204 && data.statusText === 'No Content') {
          loadWordList()
          setIsDeleteButtonDisable(false)
          const msgTxt = typeof data?.message === 'string' ? data.message : 'Deleted successfully!'
          toast.success(msgTxt)
        } else toast.error('Failed to delete document. Try again.')
      })

      .catch((error) => {
        setLoading(false)
        if (error?.response?.request?.status === 422) {
          toast.error('Failed to delete document. Try again.')
        } else if (error?.response?.request?.status === 401) {
          toast.error('Your session has expired. Please login to pick up where you left off.')
          dispatch(logout())
          navigate('/login')
          setIsDeleteButtonDisable(false)
        } else {
          toast.error('Something went wrong , Please try again later !!')
        }
      })
    setLoading(null)
    setDeleteModal(false)
    setDeleteItem(null)
    setIsDeleteButtonDisable(false)
  }
  const onConfirmCancel = () => {
    setDeleteModal(false)
    setDeleteItem(null)
  }

  const handleTranscript = (id) => {
    setTranscriptModalVisible(true)
    dispatch(getDocumentEditList(navigate, 1, id))
  }
  const handleEditLabel = (index) => {
    console.log('in editMode')
    setDocuments((prevDocList) => {
      const updatedDocList = [...prevDocList]

      updatedDocList[index].isEditable = true
      return updatedDocList
    })
  }
  const handleLabelSave = (itm, index, cId) => {
    // setCurrentEditId(cId)
    // const url = process.env.REACT_APP_API_ENDPOINT}/Configuration.datasetCategory.category + id
    const payload = {
      label: newLabel,
      temperature: '0',
      description: null,
      icon: null,
      is_visible: false,
    }
    // if (newLabel === itm?.categories[0].display_name) {
    //   setDocuments((prevDocList) => {
    //     const updatedDocList = [...prevDocList]

    //     updatedDocList[index].isEditable = false
    //     return updatedDocList
    //   })

    //   return
    // }

    axios
      .patch(
        `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.datasetCategory.category}${cId}`,

        payload,
        {
          headers: getHeaders(),
        },
      )
      .then((response) => {
        console.log('ressspppp', response)
      })
      .catch((error) => {
        // setCategoryErrorMessage(error?.response?.data?.detail[0]?.msg)
        return error.response
      })
    setNewLabel('')
    // getAllUploadedDocs()
    setDocuments((prevDocList) => {
      const updatedDocList = [...prevDocList]
      console.log(updatedDocList)
      updatedDocList[index].isEditable = false
      updatedDocList[index].categories[0].display_name = newLabel
      return updatedDocList
    })
  }
  return (
    <div className="content-area d-flex justify-content-between">
      <div className="upload-contents">
        <div className="breif mb-4">
          <h5>Knowledge base</h5>
          <p>
            Our Multimodal AI Knowledgebase enables AI systems to answer questions using textual
            documents, videos, and audio uploads, fostering natural language understanding and
            document-based AI advancements. It offers a versatile repository for a wide range of
            media formats, making it a valuable resource in the field.
          </p>
        </div>

        <div
          className="upload-here mb-4"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsDocModalVisible(true)}
        >
          <div className="items">
            <img src={uploadImage} alt="" />
            <h6 className="mb-1">Upload File here</h6>
            <p className="mb-0">(Documents, Audio, Video URL)</p>
          </div>
          {/* <input type="file" /> */}
        </div>

        <div>
          {showProgressbar && (
            <div className="upload-items">
              <div className="up-icon">
                <img src={uploaddImg} alt="" />
              </div>

              <div className="uploaded-data d-flex">
                <div className="progress-sc me-3">
                  <h6 className="mb-0">Uploading ...</h6>
                  <div className="progress mt-3" style={{ height: '5px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${uploadPercentage}%`, background: '#4bc641' }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                {/* <div className="close-icon  d-flex">
                    <a href="#">
                      <img src={closeImage} alt="" />
                    </a>
                  </div> */}
              </div>
            </div>
          )}
          {documents?.map((itm, index) => (
            <div className="upload-items uploded" key={itm?.id}>
              <div className="up-icon">
                <img
                  src={
                    itm?.document_name?.slice(-4).includes('pdf')
                      ? pdfImage
                      : itm?.document_name?.slice(-4).includes('doc')
                      ? docImage
                      : itm?.document_name?.slice(-4).includes('xml')
                      ? xmlImage
                      : itm?.document_name?.slice(-4).includes('mp3')
                      ? mp3Image
                      : urlImage
                  }
                  alt=""
                />
              </div>

              <div className="uploaded-data d-flex">
                <div className="progress-sc me-3">
                  <h6 className="mb-0">
                    {itm?.isEditable === true ? (
                      <input
                        onChange={(e) => setNewLabel(e.target.value)}
                        defaultValue={itm?.categories[0]?.display_name}
                        type="text"
                        className="edit-input"
                        style={{
                          background: 'transparent',
                          borderBottom: '1px solid white',
                          borderTop: '0px',
                          borderRight: '0px',
                          borderLeft: '0px',
                          color: '#fff',
                        }}
                      />
                    ) : (
                      <a href="#">{itm?.categories[0].display_name}</a>
                    )}

                    {itm?.status === 'Completed' && (
                      <CIcon
                        style={{ marginLeft: '12px', cursor: 'pointer' }}
                        icon={itm?.isEditable === true ? cilCheckCircle : cilPen}
                        onClick={() => {
                          {
                            newLabel?.length === 0 && setNewLabel(itm?.categories[0]?.display_name)
                          }
                          itm?.isEditable === true
                            ? handleLabelSave(itm, index, itm?.categories[0].id)
                            : handleEditLabel(index, itm?.id)
                        }}
                      />
                    )}
                    {/* {categoryErrorMessage?.length !== 0 && itm?.id === currentEditId && (
                          <p className="text-danger">{categoryErrorMessage}</p>
                        )} */}

                    {itm?.status === 'Completed' &&
                      (itm?.document_name?.slice(-4).includes('pdf') ? (
                        ''
                      ) : itm?.document_name?.slice(-4).includes('doc') ? (
                        ''
                      ) : itm?.document_name?.slice(-4).includes('xml') ? (
                        ''
                      ) : (
                        <span onClick={() => handleTranscript(itm?.id)} className="cstm-transcript">
                          transcript
                        </span>
                      ))}
                  </h6>
                </div>
                <div className="close-icon d-flex">
                  <input
                    onClick={() => {
                      categoryId === itm?.categories[0]?.id
                        ? setCategoryId(null)
                        : setCategoryId(itm?.categories[0]?.id)
                      setFirstMessage(true)
                      itm?.status === 'Completed' &&
                        setCategoryTitle(itm?.categories[0]?.display_name)
                    }}
                    disabled={itm?.status === 'Invalid'}
                    type="button"
                    className="btn btn-primary btn-sm me-2 text-white px-4"
                    // style={{
                    //   border: itm?.active === false && '0',
                    //   background:
                    //     itm?.active === true
                    //       ? categoryId === itm?.categories[0]?.id && '#1e2d1e'
                    //       : '#ffa500',
                    // }}

                    style={{
                      border:
                        itm?.status === 'Completed'
                          ? '1px solid #4bc641'
                          : itm?.status === 'Invalid'
                          ? '1px solid #bf0000'
                          : itm?.status === 'Processing'
                          ? '1px solid #ffa500'
                          : '0',

                      background:
                        itm?.status === 'Completed'
                          ? categoryId === itm?.categories[0]?.id
                            ? 'black'
                            : '#4bc641'
                          : itm?.status === 'Invalid'
                          ? '#bf0000'
                          : itm?.status === 'Processing'
                          ? '#ffa500'
                          : '',
                    }}
                    // value={
                    //   itm?.active === true
                    //     ? categoryId === itm?.categories[0]?.id
                    //       ? 'Active'
                    //       : 'Select'
                    //     : 'Processing'
                    // }

                    value={
                      itm?.status === 'Completed'
                        ? categoryId === itm?.categories[0]?.id
                          ? 'Active'
                          : 'Select'
                        : itm?.status === 'Processing'
                        ? 'Processing'
                        : itm?.status === 'Invalid'
                        ? 'Failed'
                        : ''
                    }
                  />
                  {(itm?.status === 'Completed' || itm?.status === 'Invalid') && (
                    <a href="#" style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={closeImage}
                        alt=""
                        onClick={() => handleDeleteFile(itm?.id, itm?.categories[0]?.id)}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Chat
        categoryId={categoryId}
        categoryTitle={categoryTitle}
        expandChat={expandChat}
        setExpandChat={setExpandChat}
        setFirstMessage={setFirstMessage}
        firstMessage={firstMessage}
      />

      <div className="chatbox-icon" onClick={() => setExpandChat(!expandChat)}>
        <img src={expandChat ? closeChatImage : chatImage} alt="" />
      </div>
      {isDocModalVisible && (
        <AddModal
          setShowProgressbar={setShowProgressbar}
          visible={isDocModalVisible}
          closeModal={closeModal}
          setUploadPercentage={setUploadPercentage}
        />
      )}
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={onConfirmCancel}
        confirmDelete={onDelete}
        isDeleteButtonDisable={isDeleteButtonDisable}
        deleteButtonDisable={loading}
      />
      <TranscriptModal
        visible={transcriptModalVisible}
        transcriptData={transcript}
        onClose={() => {
          setTranscriptModalVisible(false)
          dispatch(clearDocumentEditListData())
        }}
      />
    </div>
  )
}
