import React, { useEffect, useState } from 'react'
import UserDashboard from './dashboard'
import {
  AddCircle,
  CloseRounded,
  Forum,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material'
import Input from '../../CustomGraphBot/input'
import Messages from '../../CustomGraphBot/messages'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import {
  cibBuffer,
  cilCheckCircle,
  cilPencil,
  cilPlus,
  cilSpeedometer,
  cilTrash,
} from '@coreui/icons'
import { Skeleton, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import { CModal, CModalBody, CModalHeader } from '@coreui/react'
import chatBot from '../../../assets/images/avatars/whizz-white.svg'

const DashboardLayout = () => {
  const { sidebarShow } = useSelector((state) => state.sidebar)
  const [messages, setMessages] = useState([
    {
      msg: "Hello! It's great to have you here. How can I assist you today?",
      me: false,
      _id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
      id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
    },
  ])
  const [loadingDashboard, setLoadingDashboard] = useState(false)
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [isBotVisible, setIsBotVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatloading] = useState(false)
  const [streamingStatus, setStreamingStatus] = useState(false)
  // eslint-disable-next-line
  const [dashboardName, setDashboardName] = useState('')
  const [allDBArray, setAllDBArray] = useState([])
  const [allDashboardsOfDB, setAllDashboardsOfDB] = useState([])
  const [isDashboardNav, setIsDashboardNav] = useState(false)
  const [dashboardId, setdashboardId] = useState(null)
  const location = useLocation()
  const [isCreateDashboradModal, setIsCreateDashboradModal] = useState(false)
  const [selectedDBid, setSelectedDBid] = useState('')
  const [inputPanel, setInputPanel] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    const parts = location.pathname.split('/')
    setdashboardId(parts[parts.length - 1])
  }, [location])
  function addMessage(msg) {
    setMessages((prev) => {
      console.log('message ', msg)
      // Check if the message with the given ID already exists in the array
      const existingIndex = prev.findIndex((item) => item.id === msg.id)

      if (existingIndex !== -1) {
        // If the message exists, update it with the new message
        const updatedMessages = [...prev]
        updatedMessages[existingIndex].msg = updatedMessages[existingIndex].msg + msg?.msg
        return updatedMessages
      } else {
        // If the message doesn't exist, add it as a new message
        return [...prev, msg]
      }
    })
  }
  const handleCreateNew = async () => {
    // setInputPanel(true)
    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
        title: dashboardName,
        database_id: selectedDBid,
      })
      .then((response) => {
        if (response?.status === 201) {
          setIsCreateDashboradModal(false)
          getAllDashboardsByDBid(selectedDBid)
          setInputPanel(false)
        }
      })
  }
  const listAllDB = async () => {
    const dbs = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}databases/`)
    setAllDBArray(dbs?.data)
  }

  useEffect(() => {
    listAllDB()
  }, [])

  function toggleLoading(value) {
    setLoading(value)
  }

  useEffect(() => {
    getAllDashboardsByDBid('64d4816c2b8501541c441cd1')
  }, [])

  const getAllDashboardsByDBid = (dbId) => {
    setLoadingDashboard(true)
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/?database_id=` + dbId)
      .then((resp) => {
        const modifiedData = resp?.data?.map((nav) => ({
          ...nav,
          isEditable: false,
        }))
        if (resp?.status === 200) {
          setLoadingDashboard(false)
          setAllDashboardsOfDB(modifiedData)
        }
      })
      .catch((error) => {
        setLoadingDashboard(false)
        console.log(error)
      })
  }

  const handleDeleteDashboard = async (id, dbId) => {
    let response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/` + id,
    )

    if (response?.status === 204) {
      getAllDashboardsByDBid(dbId)
    }
  }
  const handleEdit = (id, index) => {
    console.log('In 1st')
    let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)
    setAllDashboardsOfDB((prevNavList) => {
      const updatedNavList = [...prevNavList]
      updatedNavList[indexOfEdit].isEditable = true
      console.log('In 1stAA')
      return updatedNavList
    })
  }

  const handleEditDashboardName = (id, newtitle) => {
    console.log('In 2nd')
    try {
      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
          id: id,
          title: newtitle,
        })
        .then((resp) => {
          if (resp?.status === 200) {
            let indexOfEdit = allDashboardsOfDB.findIndex((obj) => obj?.id === id)
            setRefresh(!refresh)
            setAllDashboardsOfDB((prevNavList) => {
              const updatedNavList = [...prevNavList]
              updatedNavList[indexOfEdit].title = newtitle
              updatedNavList[indexOfEdit].isEditable = false

              return updatedNavList
            })
            setDashboardName('')
          } else if (resp?.status === 422) {
            toast.error('Title already exist.')
          }
        })
        .catch((error) => {
          // Handle the error here
          if (error?.response?.status === 422) {
            toast.error('Title already exist.')
          } else {
            toast.error('Something went wrong')
          }
          console.log('An error occurred:', error)
          // You can add more specific error handling based on the error response if needed
        })
    } catch (error) {
      console.error('An error occurred:', error)
      // Handle the catch block error here if necessary
    }
  }
  const [dashId, setDashbId] = useState(null)

  return (
    <div>
      <div id="wrapper" style={{ width: '96vw', paddingLeft: sidebarShow && '520px' }}>
        <div
          id="sidebar-wrapper"
          className="d-md-block d-none"
          style={{ left: sidebarShow && '534px' }}
        >
          <div className="sidebar-nav" style={{ height: '84vh' }}>
            {allDBArray.map((nav, index) => (
              <div
                // onClick={() => getAllDashboardsByDBid(nav?.id)}
                key={nav?.id}
                style={{
                  overflow: 'hidden',
                  cursor: 'pointer',
                  color: dashboardId === nav?.id ? 'white' : '#4BC641',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <div
                  className="d-flex justify-content-between"
                  style={{
                    paddingLeft: '16px',
                  }}
                >
                  <div>
                    <CIcon icon={cibBuffer} />
                    <span
                      style={{
                        width: '175px',
                        marginLeft: '8px',
                        marginRight: '8px',
                        maxWidth: '175px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontWeight: '500',
                        color: '#4BC641',
                      }}
                    >
                      {nav?.database}
                    </span>
                  </div>

                  <AddCircle
                    icon={cilPlus}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                    onClick={() => setIsCreateDashboradModal(true)}
                  />
                </div>
              </div>
            ))}
            <div style={{ marginTop: '8px' }}>
              {loadingDashboard && (
                <div className="m-2">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              )}
              {allDashboardsOfDB.map((nav, index) => (
                <div
                  onClick={() => {
                    navigate('/mychart/' + nav?.id)
                    setDashbId(nav.id)
                  }}
                  className={dashboardId === nav?.id ? 'activedatabase' : ''}
                  key={nav?.id}
                  style={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    color: dashboardId === nav?.id ? 'white' : 'white',
                    display: 'flex',
                    padding: '8px 0',
                    alignItems: 'center',
                    paddingLeft: '16px',
                  }}
                >
                  <CIcon icon={cilSpeedometer} className="mr-2" />
                  <>
                    {!nav?.isEditable ? (
                      <span
                        style={{
                          width: '148px',
                          marginLeft: '8px',
                          marginRight: '8px',
                          maxWidth: '148px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: '14px',
                        }}
                      >
                        {nav?.title}
                      </span>
                    ) : (
                      <TextField
                        id="standard-basic"
                        style={{ width: '148px', marginLeft: '8px', marginRight: '8px' }}
                        inputProps={{ style: { color: 'white' } }}
                        onChange={(e) => setDashboardName(e.target.value)}
                        defaultValue={nav?.title}
                        variant="standard"
                      />
                    )}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        nav?.isEditable
                          ? handleEditDashboardName(
                              nav?.id,
                              dashboardName === '' ? nav?.title : dashboardName,
                            )
                          : handleEdit(nav?.id, index)
                      }
                    >
                      <CIcon
                        icon={nav?.isEditable ? cilCheckCircle : cilPencil}
                        style={{ marginRight: '8px' }}
                      />
                    </span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteDashboard(nav?.id, nav?.database_id)}
                    >
                      <CIcon icon={cilTrash} />
                    </span>
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>

        <UserDashboard refresh={refresh} />
      </div>

      <>
        {isBotVisible ? (
          <div className="slide-in cstm-slide-in">
            <div className="main_cont bot-chatwindow">
              <div className="bot-headercstmboard d-flex justify-content-between">
                <p className="bot-headercstmboard-title">
                  {' '}
                  Welcome to Whizz&apos;s World: Let&apos;s Chat!!
                </p>

                <CloseRounded
                  onClick={() => {
                    setIsBotVisible(false)
                    setMessages([
                      {
                        msg: "Hello! It's great to have you here. How can I assist you today?",
                        me: false,
                        _id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                        id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                      },
                    ])
                  }}
                  style={{ fontSize: '26px', cursor: 'pointer' }}
                />
              </div>
              <div
                className="inner_cont"
                onWheel={() => setIsUserScroll(true)}
                style={{
                  height: '97%',
                  overflowY: 'auto',
                  paddingRight: '8px',
                  // marginTop: '10px',
                  background: '#121d12',
                }}
              >
                <div style={{ display: messages.length !== 0 ? 'block' : 'none' }}>
                  <Messages
                    show={loading}
                    messages={messages}
                    setMessages={setMessages}
                    cstmBoard={true}
                    toggleLoading={toggleLoading}
                    addMessage={addMessage}
                    streamingStatus={streamingStatus}
                    setChatloading={setChatloading}
                  />
                </div>
              </div>
              {messages.length > 0 && (
                <Input
                  cstmBoard={true}
                  maxwidth
                  chatLoading={chatLoading}
                  setChatloading={setChatloading}
                  toggleLoading={toggleLoading}
                  addMessage={addMessage}
                  setIsUserScroll={setIsUserScroll}
                  dashboardId={dashboardId}
                />
              )}
            </div>
          </div>
        ) : (
          <div onClick={() => setIsBotVisible(true)}>
            <img className="botShortcut-cstm" src={chatBot} alt="bot" />
          </div>
        )}
      </>
      <CModal alignment="center" visible={isCreateDashboradModal}>
        <CModalHeader className="cstmModalRadius  customBoardModalbg">
          Create new dashboard
        </CModalHeader>
        <CModalBody className="cstmModalRadiusBottom" style={{ background: '#121d12' }}>
          <label className="mb-1"> Enter dashboard name</label>
          <input
            className="form-control mb-2 cstm-text-area cstm-textbox"
            type="text"
            placeholder="Enter dashboard name"
            onChange={(e) => setDashboardName(e.target.value)}
          />
          <label className="mb-1"> Choose database</label>
          <select
            className="form-control form-select form-control mb-4 cstm-text-area cstm-textbox"
            onChange={(e) => {
              setSelectedDBid(e.target.value)
            }}
          >
            <option className=" form-control" value={''}>
              Select database
            </option>
            {allDBArray.map((db) => (
              <option value={db?.id} key={db?.id}>
                {db?.database}
              </option>
            ))}
          </select>
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-secondary"
              style={{ marginRight: '8px' }}
              onClick={() => setIsCreateDashboradModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => handleCreateNew()}
            >
              Create
            </button>
          </div>
        </CModalBody>
      </CModal>
    </div>
  )
}

export default DashboardLayout
